.view-search-products {
  .cat-contact {
    margin-bottom: 40px;
    display: flex;
    align-items: center;

    @include less-than($screen-xl) {
      flex-direction: column;
    }

    .cat-contact-content {
      margin-right: 4rem;
      font-style: italic;
      font-size: 18px;
      line-height: 138.69%;
      color: #343434;

      p {
        margin-top: 0;
      }

      a {
        color: $color-primary;
        text-decoration: underline;
        font-weight: 700;
      }
      
    }

    .cat-contact-button {
      text-transform: uppercase;
      display: flex;
      .btn-phone,
      .btn-store-locator {
        display: flex;
        align-items: center;
        white-space: nowrap;
        &::before {
          content: '';
          background-image: url(../../images/icon-map-marker.png);
          background-repeat: no-repeat;
          background-position: center center;
          width: 14px;
          height: 18px;
          margin-right: 5px;
          flex-shrink: 0;
        }
      }
      .btn-phone {
        margin-left: 2rem;
        &::before {
          background-image: url(../../images/icon-phone.png);
          width: 18px;
        }
      }

      @include less-than($screen-md) {
        flex-direction: column;
        > a{
          margin-left: 0 !important;
          margin-bottom: 2rem;
        }
      }

    }
  }

  .view-content {
    display: grid;
    grid-gap: 6.3rem 0rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin-bottom: 4rem;

    @include less-than($screen-xxl) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include less-than($screen-xl) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include less-than($screen-md) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .views-row {

    }
  }
}

#latest-bought-products {
  display: grid;
  grid-gap: 6.3rem 0;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}