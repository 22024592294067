.page-cart-checkout {
  .uc-cart-checkout-form {
    > div {
      fieldset.panel {
        border: none;
        padding: 0;
      }

      fieldset.panel {
        > legend.panel-heading {
          background-color: $color-primary;
          font-weight: bold;
          font-size: 16px;
          line-height: 100%;
          color: #0c0c0c;
          text-transform: uppercase;
          width: 100%;
          padding: 1rem 1.5rem;
          margin-bottom: 2rem;
        }

        // 2nd level panel heading
        fieldset.panel {
          margin: 0;
        }
      }

      .control-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 151.69%;
        color: #2e2e2e;
        display: inline-block;
        margin-bottom: 1rem;
      }

      .form-control {
        background: #ffffff;
        border: 1px solid #d6d6d6;
        box-sizing: border-box;
        border-radius: 1.7737px;
        height: 3.3rem;
        width: 100%;

        &.form-textarea {
          height: 6.5rem;
          resize: none;
        }
      }

      .form-textarea-wrapper {
        .grippie {
          display: none;
        }
      }

      .help-block {
        margin-bottom: 1.5rem;
      }

      #checkout-table {
        background: #fafafa;

        .table {
          border-collapse: unset;
          padding: 1rem;

          thead {
            th {
              padding: 8px;

              &:last-child {
                text-align: right;
              }
            }
          }

          tbody > tr {
            background: none;

            .checkout-product-title {
              display: flex;
              align-items: center;

              img {
                margin-right: 1rem;
              }
            }
          }
        }

        .store-pick-up-checkout-info {
          display: flex;
          flex-wrap: wrap;
          background: #fff4c1;
          border-left: 4px solid #fdd100;
          margin: -8px;
          padding: 8px;

          .store-pick-method,
          .store-pick-change-to-delivery {
            position: relative;
            font-size: 14px;
            line-height: 151.69%;
            color: #0c0c0c;
            display: flex;
            align-items: center;
            padding-left: 3rem;
            margin: 0.5rem 0;

            a {
              margin-left: 3px;
              font-weight: 500;
              text-decoration: underline;
            }

            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              height: 22px;
              width: 22px;
              background: #ffffff;
              border: 1px solid #000;
              border-radius: 50%;
            }

            &.store-pick-up-radio-selected {
              &::after {
                content: '';
                position: absolute;
                top: 5px;
                left: 5px;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: #0c0c0c;
              }
            }
          }

          .store-pick-method {
            margin-right: 3rem;
          }

          .pick-up-store-info {
            width: 100%;
          }
        }
      }

      #uc-form-field-pane-body {
        tr {
          td:last-child {
            text-align: right;
          }
        }

        #subtotal-title,
        .uc-price {
          font-weight: 700;
          font-size: 16px;
          line-height: 151.69%;
          color: #2e2e2e;
        }

        .uc-price {
          display: inline-block;
        }

        .price-suffixes {
          font-size: 12px;
          color: #777;
          margin-left: 5px;
        }

        .battery-town-store-selection-ajax.form-submit {
          display: none;
        }
      }

      #uc_order_field-pane {
        .form-item {
          margin-bottom: 1.5rem;

          &.form-type-textfield,
          &.form-type-select {
            display: flex;
            align-items: center;

            .control-label {
              width: 18.5rem;
              margin-right: 1rem;
              margin-bottom: 0;
            }
          }

          &.form-type-textarea {
            margin-bottom: 2rem;
          }

          &.form-type-item {
            .control-label {
              display: none;
            }

            .esign_panel {
              margin-bottom: 1rem;

              canvas {
                border: 1px solid #d6d6d6;
              }

              .clear-container {
              }
            }
          }
        }
      }

      #customer-pane {
        .panel-body {
          margin-bottom: 3rem;
          .help-block {
            a {
              font-weight: bold;
              text-decoration: underline;
              &:hover {
                color: #00a4d8;
                text-decoration: none;
              }
            }
          }
        }
      }

      #delivery-pane {
        legend.panel-heading {
          display: none;
        }
      }

      #billing-pane {
        grid-area: bill;

        .form-item-panes-billing-copy-address,
        .help-block {
          margin-bottom: 1.5rem;
        }
      }

      #quotes-pane {
        display: none;
        button.btn-default {
          font-size: 14px;
        }
      }

      #coupon-pane {
        margin-bottom: 2rem;

        .panel-body {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;

          .help-block {
            width: 100%;
          }

          .form-item.form-item-panes-coupon-code {
            margin-right: 1rem;
            .control-label {
              font-weight: bold;
            }
          }

          #uc-coupon-active-coupons {
            margin: 2rem 0;
            width: 100%;
            border-radius: 8px;
            padding: 1rem;
            border: 1px dashed #d6d6d6;
            .control-label {
              font-weight: bold;
              margin-bottom: 0;
            }
          }

          .form-item {
            flex: 1;
            margin: 0;
          }

          .btn.form-submit {
            font-size: 1.6rem;
            height: 3.3rem;
            @include button(#0c0c0c);
            //margin-left: 1.5rem;
          }
        }
      }

      #coupon_automatic-pane {
        margin-bottom: 2rem;
      }

      #payment-pane {
        .panel-body {
          display: flex;
          flex-direction: column;

          .form-item-panes-payment-payment-method {
            order: 1;

            .form-radios {
              .control-label {
                display: flex;
                align-items: center;
                line-height: 1.8;

                > input {
                  margin-right: 1.5rem;
                }

                > div {
                  margin-left: auto;
                }

                img {
                  max-height: 2rem;
                }
              }
            }
          }

          #line-items-div {
            order: 2;
            border: none;
            margin: 0;

            table {
              width: 100%;
            }
          }

          #payment-details {
            order: 3;
            margin-bottom: 2rem;
          }
        }
      }

      #edit-actions {
        display: flex;
        flex-direction: column-reverse;
        //margin-top: 3rem;

        button {
          &#edit-continue {
            margin: 1rem 0;
            @include button(#77b726);
          }

          &#edit-cancel {
            background-color: transparent;
            font-weight: bold;
            font-size: 16px;
            line-height: 149.69%;
            text-align: center;
            color: #00a4d8;
          }
        }
      }

      .address-pane-table {
        table tbody {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -1rem;

          tr {
            width: 50%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 3rem;
            padding: 0 0.8rem;

            &.field-billing_street1,
            &.field-billing_street2,
            &.field-delivery_street1,
            &.field-delivery_street2,
            &.field-delivery_country,
            &.field-billing_country {
              width: 100%;
            }

            td {
              padding: 0;

              &.field-field {
                width: 100%;
              }
            }

            .field-label {
              text-align: left;
              display: flex;
              flex-direction: row-reverse;
              font-weight: 400;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }

  .block-battery-town-pickup-selection {
    .checkout-store-pick-selection {
      .store-selection-block {
        position: fixed;
        z-index: 111;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
      }

      .store-selection-content {
        background-color: #fff;
        margin: 50vh auto 0;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
        transform: translateY(-50%);
      }

      .store-selection-table {
        width: 100%;
        font-size: 1.6rem;
        line-height: 138.69%;
        color: #222222;

        thead {
          tr {
            background: #f0f0f0;

            th {
              padding: 1rem;
              text-align: left;
              font-weight: 700;

              &.th-delivery {
                display: none;
              }
            }
          }
        }

        tbody {
          tr td {
            padding: 1rem;
            border-bottom: 1px solid #d8d8d8;

            a {
              display: block;
              font-size: 14px;
              line-height: 138.69%;
              text-decoration-line: underline;
              color: #2487e2;
            }

            &.store-selection-select {
              font-size: 1.4rem;

              .pick-up-label {
                font-weight: 700;
              }

              .store-method-not-available {
                color: #ce4f33;
                font-weight: 700;
              }
            }

            &.delivery-select {
              display: none;
            }
          }
        }
      }

      .btn-wrapper {
        text-align: right;
        margin-top: 3rem;
      }
    }
  }

  // Checkout Page Grid Layout
  .uc-cart-checkout-form {
    > div {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: auto;
      column-gap: 5.5rem;
      grid-template-areas:
        'cart customer coupon'
        'cart delivery coupon'
        'cart delivery quotes'
        'cart delivery coupon-auto'
        'cart delivery payment'
        'cart bill payment'
        'cart bill actions';

      // "cart customer quotes"
      // "cart delivery quotes"
      // "cart delivery coupon"
      // "cart delivery coupon-auto"
      // "cart delivery payment"
      // "cart bill payment"
      // "cart bill actions";

      @include less-than($screen-lg) {
        display: flex;
        flex-direction: column;
      }

      #uc_form_field-pane {
        grid-area: cart;
        margin-bottom: 2rem;
      }

      #uc_order_field-pane {
        grid-area: quotes;
      }

      #customer-pane {
        grid-area: customer;
      }

      #delivery-pane {
        grid-area: delivery;
      }

      #billing-pane {
        grid-area: bill;
      }

      #quotes-pane {
        grid-area: quotes;
        margin-bottom: 2rem;
      }

      #payment-pane {
        //grid-area: payment;

        @include less-than($screen-lg) {
          order: 10;
        }
      }

      #coupon-pane {
        grid-area: coupon;
      }

      #coupon_automatic-pane {
        grid-area: coupon-auto;
      }

      #edit-actions {
        //

        @include less-than($screen-lg) {
          order: 11;
        }
      }
    }
  }

  #coupon-messages {
    .alert {
      padding-right: 4rem;
    }
  }

  // B2B Checkout Page Grid Layout
  &.logged-in {
    .uc-cart-checkout-form {
      > div {
        grid-template-areas:
          'cart customer coupon'
          'cart delivery coupon'
          'cart delivery quotes'
          'cart delivery coupon-auto'
          'cart delivery payment'
          'cart bill payment'
          'cart bill actions';

        // "cart customer quotes"
        // "cart delivery quotes"
        // "cart . quotes"
        // "cart . coupon"
        // "cart . coupon-auto"
        // "cart . payment"
        // "cart . actions";
      }

      // #edit-actions {
      //   grid-area: actions;
      // }
    }
  }
}
