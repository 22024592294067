/*------------------------------------*\
   Header
\*------------------------------------*/

header {
  display: flex;
  flex-direction: column;
  position: sticky;
  //position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.header-mobile {
  display: none;
  margin-right: auto;

  p {
    margin: 0;
  }

  @include less-than($screen-md) {
    display: block;
  }
}

.header-top {
  width: 100%;
  color: #fff;
  background-color: $color-black;
  font-size: 1.4rem;

  @include less-than($screen-md) {
    display: none;
  }

  a {
    color: $color-primary;
    font-weight: bold;
    padding: 0 5px;
  }

  p {
    margin: 0;
  }

  .alertbar {
    flex-basis: 100%;
  }

  .region-headertop {
    width: 100%;
    display: flex;
    align-items: center;
    height: 5rem;
    flex-wrap: wrap;
    min-height: 5rem;
    height: auto;

    .top-contact {
      margin-right: auto;
      flex: 1;
      padding-left: 5rem;

      @include less-than($screen-lg) {
        display: none;
      }

      @include less-than($screen-xl) {
        padding-left: 4rem;
      }
    }

    .top-menu {
      @include less-than($screen-lg) {
        display: none;
      }
    }

    .header-trade-login {
      align-self: stretch;

      @include less-than($screen-lg) {
        display: none;
      }

      p {
        height: 100%;
      }

      &__link {
        background: #b72525;
        color: #fff;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 138.69%;
        color: #ffffff;
        height: 100%;
        width: 13rem;
        display: flex;
        align-items: center;
        justify-content: center;
        clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);

        p {
          margin: 0 !important;
        }
      }
    }
  }
}
/*
======================================================
 Header Container
======================================================
*/
.header-container {
  padding: 0;
  background-color: $color-primary;

  > div {
    display: flex;
    position: relative;

    .hamburger-menu {
      position: relative;
      margin-right: 0;
      padding-right: 0;
      height: 81px;

      &.is-active {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .region-header {
    display: flex;
    align-items: center;
    height: 9.8rem;
    flex: 1;

    /** Responsive **/
    @include less-than($screen-md) {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    @include less-than($screen-lg) {
      flex-wrap: wrap;
      height: auto;
      justify-content: flex-end;
    }
  }
  /*
  ======================================================
   Header Logo
  ======================================================
  */
  .header-logo {
    margin-right: 7rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    img {
      height: 6.8rem;
      object-fit: contain;
    }

    /** Responsive **/
    @include less-than($screen-xl) {
      margin-right: 3rem;
    }

    @include less-than($screen-lg) {
      margin-right: auto;
    }

    @include less-than($screen-md) {
      display: none;
    }
  }

  /*
  ======================================================
  Header Text
  ======================================================
  */
  .header-text {
    margin: 0 1rem;

    ul {
      display: grid;
      grid-gap: 0.4rem 5rem;
      grid-template-rows: repeat(3, minmax(0, 1fr));
      grid-template-columns: repeat(2, auto);
      grid-auto-flow: column;
      font-style: italic;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 100%;

      @include less-than($screen-xxl) {
        grid-gap: 0.4rem 3rem;
      }

      @include less-than($screen-xl) {
        font-size: 1.4rem;
        grid-gap: 0.4rem 2rem;
      }

      li:nth-child(2) {
        grid-row-end: span 2;
      }
    }

    @include less-than($screen-lg) {
      display: none;
    }
  }

  .header-search-mobile {
    display: none;
    p {
      margin: 0;
    }
    @include less-than($screen-lg) {
      display: block;
    }
  }
  /*
  ======================================================
    Header Search
  ======================================================
  */
  .header-search {
    flex: 1;
    margin-right: 1rem;

    @include less-than($screen-lg) {
      order: 2;
      width: 100%;
      flex-basis: 100%;
      margin-right: 0;

      &:not(.show) {
        display: none;
      }

      > form {
        max-width: 100%;
      }
    }

    > form {
      max-width: 54rem;
      margin: 0 auto;
    }

    .views-exposed-widgets {
      display: flex;
      align-items: center;

      .views-widget-filter-search_api_views_fulltext {
        padding: 0;
        flex: 1;

        input.form-control {
          height: 4.2rem;
          border-radius: 0;
          font-size: 14px;
          line-height: 17px;
          color: #4b4b4b;
        }
      }

      .views-submit-button {
        padding: 0;

        .form-submit {
          margin-top: 0;
          font-size: 0;
          width: 4.8rem;
          height: 4.2rem;
          background: url(../../images/icon-search.png) #000;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 0;

          @include less-than($screen-xl) {
            width: 4rem;
          }
        }
      }
    }
  }
  /*
  ======================================================
  Header Cart
  ======================================================
  */
  .header-cart {
    position: relative;
    padding-right: 2.7rem;

    @include less-than($screen-lg) {
      margin-right: 6rem;
    }

    @include less-than($screen-sm) {
      margin-right: 5rem;
      margin-left: 1rem;
    }

    @include less-than($screen-md) {
      .cart-block-summary {
        &::before {
          content: '';
          height: 3.3rem !important;
        }
      }
    }

    .block-title {
      display: none;
    }

    .cart-block-summary {
      display: flex;
      flex-direction: column;
      align-items: center;

      tbody {
        height: 0;
      }

      td {
        padding: 0;
      }

      &::before {
        content: '';
        background-image: url(../../images/icon-cart.png);
        background-repeat: no-repeat;
        background-position: center;
        width: 3.6rem;
        height: 4.1rem;
      }

      &::after {
        content: 'cart';
        font-weight: 700;
        font-size: 12px;
        line-height: 138.69%;
        text-align: center;
        color: #1f1f1f;
      }

      .cart-block-summary-items {
        position: absolute;
        right: 0;
        font-size: 0;
        top: 6px;

        .num-items {
          background: #ffffff;
          border: 2px solid #ffffff;
          box-sizing: border-box;
          border-radius: 36.3898px;
          font-weight: 700;
          font-size: 15.3103px;
          line-height: 18px;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .cart-block-summary-total {
        display: none;
      }

      .cart-block-summary-links {
        .cart-block-checkout {
          display: none;
        }

        .cart-block-view-cart {
          padding: 0;
          border-right: none;
          a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 1rem;
            font-size: 0;
          }
        }
      }
    }
  }

  /*
  ======================================================
   Header User
  ======================================================
  */
  .header-user {
    @include less-than($screen-md) {
      display: none;
    }

    .block-title {
      display: none;
    }

    .menu.nav > li {
      > a {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 700;
        font-size: 12px;
        line-height: 138.69%;
        text-align: center;
        color: #1f1f1f;
        padding: 0 1rem;
        &::before {
          content: '';
          background-image: url(../../images/icon-user-lock.png);
          background-repeat: no-repeat;
          width: 4.1rem;
          height: 4.1rem;
          display: inline-block;
          background-position: center;
        }
      }

      /** Dropwdown **/
      > .dropdown-menu {
        > li {
          > a {
            padding: 0 1rem;
          }
        }
      }
    }
  }

  .header-mobile-search,
  .header-find-store {
    margin-left: auto;

    @include less-than($screen-lg) {
      display: none;
    }

    &__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 700;
      font-size: 12px;
      line-height: 138.69%;
      text-align: center;
      color: #1f1f1f;
      cursor: pointer;
    }
  }

  .header-mobile-search {
    &__link {
      img {
        height: 3rem;
        margin-bottom: 0.4rem;
        //margin-top: 0.5rem;
      }
    }
  }
}

/*
======================================================
 Header Menu
======================================================
*/
.header-menu {
  background-color: $color-black;

  .region-headermenu {
    display: flex;
    position: relative;

    .main-menu {
      @include less-than($screen-lg) {
        display: none;
      }
    }

    .header-menu-links {
      @include less-than($screen-lg) {
        display: none;
      }
      .store-finder-links {
        margin: 0;
        display: flex;
        height: 100%;

        a {
          color: #fff;
          display: flex;
          align-items: center;
          padding: 0rem 2rem;
          font-weight: 700;
          white-space: nowrap;

          img {
            margin-right: 7px;
          }

          @include less-than($screen-xl) {
            padding: 0 0.5rem;
          }
        }
      }
    }

    .header-vehicle-search-tabs {
      margin-left: auto;
      position: static;
      height: 5.6rem;

      @include less-than($screen-sm) {
        margin-right: auto;
      }

      &__wrapper {
        height: 100%;
        display: flex;
        color: #fff;
        font-weight: 700;
        font-size: 15px;
        line-height: 138.69%;
        align-items: center;
        > span {
          padding-right: 1rem;
          @include less-than($screen-xl) {
            display: none;
          }
        }
      }
      &__list {
        list-style: none;
        display: flex;
        height: 100%;

        li {
          width: 55px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(180deg, #2b2b2b 0%, #242424 100%);
          border-right: 1px solid #161616;
          cursor: pointer;

          &.active,
          &:hover {
            background: $color-primary;
            img {
              filter: brightness(0%);
            }
          }
        }
      }
    }

    .header-menu-search-popup {
      position: absolute;
      top: 5.6rem;
      right: 0;
      left: 0;
      background-color: #fff;
      z-index: 111;
      padding: 3rem;
      display: none;
      border: 1px solid #ccceee;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

      @include less-than($screen-lg) {
        height: calc(100vh - 15.4rem);
        overflow-y: auto;
        width: 100vw;
        left: calc(0px - (100vw - 100%) / 2);
      }

      &.show {
        display: block;
      }

      &__close {
        position: absolute;
        top: 2rem;
        right: 2rem;
      }

      &__tabs {
        list-style: none;
        display: flex;
        position: absolute;
        top: 3rem;
        left: 3rem;
        li {
          background: #e9e9e9;
          border-radius: 100%;
          width: 3.9rem;
          height: 3.9rem;
          margin-right: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &.active,
          &:hover {
            background: $color-primary;
          }
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .mega-menu-search {
        display: none;
        &.show {
          display: block;
        }

        &__header {
          padding-left: 20.5rem;
          display: flex;
          align-items: center;
          height: 3.9rem;
          margin-bottom: 1.5rem;

          @include less-than($screen-sm) {
            padding-left: 18rem;
          }

          > label {
            font-weight: 700;
            font-size: 18px;
            line-height: 138.69%;
            color: #000000;
            margin: 1rem 2rem 1rem 0;
          }
        }
      }
    }
  }
}
