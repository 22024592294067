.page-user {
  .region-content {
    form {
      margin: 0 auto;

      .control-label {
        display: flex;
        margin-bottom: 1rem;
        font-weight: bold;
      }

      .help-block {
        font-size: 12px;
        display: none;
      }

      button[type='submit'] {
        background: #333;
        color: #fff;
        border-radius: 3px;
        padding: 5px 20px;
        display: block;
        margin-left: auto;
      }
    }
  }

  form#user-login {
    max-width: 40rem;
  }

  form#role-delegation-roles-form {
    .form-item-roles-change {
      display: inline-block;
    }

    label {
      margin-bottom: 15px;
      font-weight: 700;
      display: block;
    }

    .form-checkboxes {
      display: inline-block;

      .radio,
      .checkbox {
        position: relative;

        label {
          font-weight: normal;
          margin-bottom: 0;
          display: inline-block;
        }
      }
    }
  }

  // Table Orders - if link has tracking word, mainfreight.com
  .view-uc-orders-user {
    td.views-field {
      > a {
        &[href*='mainfreight.com'],
        &[href*='tracking'] {
          @extend .btn-secondary;
          color: $color-primary;
          text-decoration: none;
          &::before {
            content: '\f0d1';
            font-weight: normal;
            margin-right: 1rem;
            font-family: $font-awesome;
          }
        }
      }
    }
  }
}

/*
======================================================
 User
======================================================
*/
.page-user {
  form {
    margin-left: 0 !important;

    @include larger-than($screen-md) {
      width: 40rem;
    }

    &#user-login {
      a {
        margin-bottom: 1rem;
        display: flex;

        &:hover {
          text-decoration: none;
          color: $color-primary;
        }

        &[href='/user/password'] {
          display: flex;
          font-weight: bold;

          &::before {
            content: '\f084';
            font-family: $font-awesome;
            text-decoration: none;
            line-height: 1;
            margin-right: 0.5rem;
            color: $color-primary;
          }
        }
      }
    }
  }

  &.admin-menu {
    .view-uc-orders-user {
      .views-field-order-status {
        display: none;
      }

      tbody {
        a {
          text-decoration: underline;
        }
      }
    }
  }
}
