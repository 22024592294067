footer {
  color: #fff;
  font-size: 13px;
  line-height: 155.4%;
  letter-spacing: 1px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 4rem;
  background-image: url(../../images/footer-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  h2.block-title {
    font-size: 14px;
    color: $color-primary;
    text-transform: uppercase;
  }
  .footer {

    padding: 5rem 0 3rem;
  }
  .footer-bottom {
    background-color: #080A0D;
    padding: 5px 0;
    @include less-than($screen-lg) {
      background-color: transparent;

    }
  }

  .region-footer {
    display: flex;

    @include less-than($screen-lg) {
      flex-direction: column;
    }

    ul.menu.nav {
      display: flex;
      flex-direction: column;

      > li {
        margin-bottom: 0.5rem;

        a {
          line-height: 1.8;
          padding: 0;
        }

        &.show-on-mobile {
          display: none;
        }
      }
      &.dropdown-menu {
        display: none;
      }
    }

    .footer-description {
      flex-basis: 26rem;
      margin-right: 9rem;

      @include less-than($screen-lg) {
        flex-basis: auto;
      }
    }

    .footer-menu {
      flex-basis: 16rem;
      margin-right: 4.7rem;
      ul.menu.nav {
        column-count: 2;
        column-gap: 2rem;
        display: block;
        white-space: nowrap;
      }
    }

    .footer-contact {
      margin-left: auto;
      flex-basis: 30rem;
      margin-top: 1rem;
      a {
        color: #fff;
        text-decoration: underline;
      }
      @include less-than($screen-lg) {
        flex-basis: auto;
        margin-left: 0;
      }
    }
  }

  .region-copyright {
    display: flex;
    align-items: center;
    padding: 1rem 0;

    @include less-than($screen-lg) {
      flex-direction: column;
    }

    p {
      margin: 0;
    }

    .footer-social-media {
      margin-left: auto;

      @include less-than($screen-lg) {
        margin-left: 0;
        align-self: flex-start;

      }

      a {
        margin: 0 0.5rem;
      }
    }
  }
}