.download-pdf {
  .file {
    .file-icon,
    .file-size {
      display: none;
    }
    > a {
      display: block;
      position: relative;
      width: 200px;
      height: 91px;
      background: url('../../images/downloadpdf-btn.png') center center
        no-repeat;
      color: transparent;
      background-size: contain;
    }
  }
}
