.page-cart {
  .easy-breadcrumb {
    display: none;
  }

  #cart-form-pane {
    max-width: 100%;

    form {
      .clearfix {
        display: none;
      }
    }

    .table-responsive {
      overflow: auto;
      max-width: 100%;
    }

    table {
      width: 100%;
      min-width: 40rem;

      @include less-than($screen-md) {
        font-size: 1.4rem;
      }

      thead {
        background-color: $color-primary;

        tr {
          display: flex;
          width: 100%;
        }

        th {
          height: 4rem;
          vertical-align: inherit;
          padding: 0 2rem;
          text-align: center;
          line-height: 4rem;
          border: none;
          text-align: left;
          flex-shrink: 0;

          &:first-child {
            order: 4;
            width: 15%;
          }

          &:nth-child(2) {
            order: 0;
            width: 15%;
          }

          &:nth-child(3) {
            order: 1;
            width: 30%;
            margin-right: auto;
          }

          &:nth-child(4) {
            order: 3;
            width: 20%;
          }

          &:nth-child(5) {
            order: 2;
            width: 20%;
          }

          @include less-than($screen-lg) {
            padding: 0 1rem;
          }
        }
      }

      tbody {
        tr {
          display: flex;
          width: 100%;
          background: none;

          td {
            height: 106px;
            vertical-align: inherit;
            padding: 0 2rem;
            text-align: left;
            border-bottom: 1px solid #e8e8e8;
            display: flex;
            align-items: center;
            flex-shrink: 0;

            &.image {
              order: 0;
              width: 15%;

              img {
                padding-right: 0;
              }
            }

            &.desc {
              order: 1;
              width: 30%;
              margin-right: auto;
            }

            &.price {
              order: 2;
              width: 20%;

              .price-suffixes {
                font-size: 12px;
                color: #666;
                margin-left: 5px;
              }
            }

            &.qty {
              order: 3;
              width: 20%;

              .form-group {
                margin-bottom: 0;
              }
            }

            &.remove {
              order: 4;
              width: 15%;

              button {
                font-size: 0;
                background-image: url(../../images/icon-trash.png);
                background-color: transparent;
                background-repeat: no-repeat;
                display: inline-block;
                width: 24px;
                height: 24px;
                cursor: pointer;
                padding: 0;
              }
            }

            &.subtotal {
              display: flex;
              justify-content: flex-end;
              width: 100%;
              font-weight: 700;
              font-size: 16px;
              line-height: 138.69%;
              color: #222222;
              height: 47px;

              .uc-price {
                margin-left: 2rem;
              }
            }
          }

          input {
            border: 1px solid #eee;
            text-align: center;
            border-radius: 3px;
            height: 30px;
            color: #333;
          }
        }
      }
    }

    .form-actions {
      margin-top: 2rem;
      display: flex;
      justify-content: flex-end;

      button {
        text-align: center;
        width: 200px;
        min-width: 150px;
        margin: 0 1rem 0 0;
        cursor: pointer;
      }

      a {
        margin-right: auto;
      }

      a,
      button#edit-empty {
        font-size: 1.6rem;
        background: none;
        border: none;
        color: #000;
        font-weight: 400;
        padding: 1.2rem 1rem;
        width: 16rem;

        &:hover {
          text-decoration: underline;
        }
      }

      button[name='op'],
      button#edit-empty {
        font-size: 1.6rem;
        @include button($color-warning);
      }

      button[name='update-cart'] {
        display: none;
      }

      button#edit-checkout--2 {
        font-size: 1.6rem;
        @include button($color-primary);
      }

      @include less-than($screen-md) {
        width: 100%;
        flex-direction: column;

        > a,
        > button,
        button#edit-empty,
        button[name='update-cart'] {
          margin-bottom: 1rem;
          width: 100%;
          margin: 1rem 0;

          @include button(#000);
        }
      }
    }
  }

  #uc-cart-pane-coupon {
    margin-top: 4rem;
    border: none;
    background-color: #fafafa;

    > form > div {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;

      @include less-than($screen-md) {
        flex-direction: column;
        align-items: stretch;
      }

      h3 {
        width: 100%;
      }

      .form-item {
        margin-right: 2rem;
        margin-bottom: 1rem;

        label {
          padding-bottom: 1rem;
          display: inline-block;
        }
      }

      button {
        height: 3.6rem;
        padding: 6px 20px;
        color: #000;
        font-size: 1.6rem;
        margin-bottom: 1rem;
      }
    }
  }

  #cart-form-pane {
    .qty {
      .form-item {
        display: flex;
        justify-content: center;
        align-items: center;
        .form-control {
          border-radius: 0;
        }
        .increment {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #eee;
          cursor: pointer;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}
