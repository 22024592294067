.page-deep-cycle-calculator {
	.deep-cycle-calculator-top {
    margin: 10px 0 20px;
    font-size: 16px;
		width: 70%;
		float: left;
	}
	#block-system-main {
		width: 70%;
		float: left;
	}
  
	.right-side-disclaimer {
		width: 25%;
		margin-left: 5%;
		float: right;
		padding: 20px 40px;
		background-color: #000;
		color: #fff;
		font-weight: 400;
		font-style: italic;
		font-size: 13px;
		line-height: 1.5;

		h3 {
			color: #FAC526;
			font-weight: 700;
			font-style: normal;
			font-size: 15px;
		}
	}
	@include less-than($screen-xl){
		.region-content {
			display: flex;
			flex-direction: column;
		}
		.deep-cycle-calculator-top,
		#block-system-main,
		.right-side-disclaimer {
			width: 100%;
			margin: 0;
		}

		.right-side-disclaimer {
			order: 4;
		}
	} 
}

#deep-cycle-calculator-form {
	margin-top: 30px;

	> div > fieldset:nth-child(odd),
	#rows fieldset:nth-child(even){
		input {
			background-color: #F9F9F9;
		}
	}
	fieldset {
		border: none;
		box-shadow: none;
		margin-bottom: 20px;
		padding-left: 50px;
		position: relative;

		&#edit-title {
			font-size: 16px;
			line-height: 1.2;
			font-weight: 500;
			margin-bottom: 10px;
			min-height: 40px;
			word-break: break-word;
		}

		.panel-heading {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 50px;
			height: 50px;
			background: transparent;
			border: 0;

		}
		
		.panel-body {
			display: flex;
			padding: 0;

			#row-title-device,
			.form-item[class*="-device"] {
				width: 34%;
				padding-right: 15px;
			}
			
			#row-title-number,
			.form-item[class*="-number"] {
				width: 22%;
				padding-right: 15px;
			}

			#row-title-watts,
			.form-item[class*="-watts"] {
				width: 22%;
				padding-right: 15px;
			}

			#row-title-hours,
			.form-item[class*="-hours"] {
				width: 22%;
			}
			
		}

    @include less-than($screen-md){
			&#edit-title {
				font-size: 14px;
			}

			.panel-body {	
				.form-group {
					margin-bottom: 0;
				}
				#row-title-device,
				.form-item[class*="-device"],
				#row-title-number,
				.form-item[class*="-number"],
				#row-title-watts,
				.form-item[class*="-watts"] {
					padding-right: 10px;
				}
			}
		} 
	}
	.form-submit {
		background-color: transparent;
		color: #056AB2;
		border-color: transparent;
		padding-left: 0;
		font-size: 16px;
		font-weight: 400;
		
		.glyphicon-plus:before {
            content: "\2295";
            font-size: 22px;
        }
	}

	#calculate_result {
		display: flex;
		border-style: dotted;
		border-color: #DDDDDD;
		border-width: 1px 0 1px 0;
		justify-content: center;
		margin-top: 20px;
		>div {
		    display: flex;
		    flex-wrap: wrap;
		    font-size: 16px;
		    line-height: 1.2;
		    font-weight: 400;
		    color: #5C5C5C;
		    padding: 20px 40px;
			justify-content: center;
			align-items: center;
		    text-align: center;

				label {
					width: 100%;
				}
				span {
					font-size: 40px;
					font-weight: 500;
					padding-top: 10px;
						padding: 0 10px;
				}
				span.value {
					color: #1C1C1C;
				}
				span.calculate_result_unit {
					font-size: 20px;
				}
		}
	}

	#searched_product {
		display: flex;
		flex-wrap: wrap;
		margin-top: 40px;

		.node-product {
			width: 30%;
            background: #f3f3f3;
            padding: 15px;
            text-align: center;
			position: relative;
			margin-bottom: 20px;
			display: inline-block;


			&:not(:last-child) {
				margin-right: 5%;
			}

			@include less-than($screen-md){  
				width: 94%;
				margin: 0 3% 20px;
				&:not(:last-child) {
					margin-right: 3%;
				}
			} 
			
			.field-name-uc-product-image {
			    img {
			        height: 180px;
					width: 100%;
			        object-fit: cover;
			    }
			}
			
			.field-name-title {
			    margin: 10px 0;
			    a {
			    color: #333333;
				font-size: 19px;
				text-decoration: none;
			    }
				
			}
			
			.field-name-body {
			    margin-bottom: 5px;
			}
			
			.product-info {
			    display: inline-block;
				width: 100%;
			}

			.field-name-field-value-designation {

				.vocabulary-product-quality {
					h2 {
						position: absolute;
						top: 15px;
						left: 0;
						margin: 0;
						padding: 5px 25px 1px 15px;
						font-size: 18px;
						font-weight: 900;
						display: inline-block;
						box-sizing: border-box;
						height: 30px;
						box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.45);

						&::after {
							content: "";
							position: absolute;
							right: -15px;
							top: 0;
							width: 0;
							height: 0;
							border-width: 15px 15px 15px 0;
							border-style: solid;
							border-right-color:transparent;
						}

						a {
							font-size: inherit;
							font-weight: inherit;
							pointer-events: none;
							text-transform: uppercase;
							color: #fff;
							line-height: 1.2;
						}
					}

					.content {
						display: none;
					}

					&.flooded h2{
						background-color: #C8841F;
						&::after {
							border-top-color: #C8841F;
							border-bottom-color: #C8841F;
						}
					}
					&.agm h2{
						background-color: #68AB3E;
						&::after {
							border-top-color: #68AB3E;
							border-bottom-color: #68AB3E;
						}
					}
					&.lead h2{
						background-color: #2AA3BD;
						&::after {
							border-top-color: #2AA3BD;
							border-bottom-color: #2AA3BD;
						}
					}
				}
			}
		}
	}
}