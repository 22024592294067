.page-header {
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  color: #222222;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.accordion {
  display: flex;
  flex-direction: column;
}

.radio , .checkbox {
  margin-bottom: 1rem;
  label {
    min-height: 16pt;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
  }
}

#block-masquerade-masquerade {
  form {
    height: 100%;
    >div {
      height: 100%;
    }
    .container-inline {
      display: flex;
      align-items: center;
      height: 100%;

      .form-group {
        margin-bottom: 0;
      }
      input.form-control {
        height: 3rem;
      }
    }
  }
}

/** Basic Page **/
.node-page {
  .field-name-body {
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 149.19%;
      color: #484848;
      margin-top: 0;
    }

    a {
      text-decoration: underline;
    }

    h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      color: #1E1E1E;

    }

    h3 {
      font-weight: 700;
      font-size: 25px;
      line-height: 138.69%;
      text-align: center;
      color: #222222;
      a {
        color: $color-primary;
      }
    }

    table {
      border: none;

      @include less-than($screen-lg) {
        tbody tr{
          display: flex;
          flex-direction: column;
        }
      }
      td {
        border: none;
        img {
          height: 100%;
          width: auto;
          max-width: 100%;
        }

      }
    }

    .warranty-text-box {
      border: 1px solid #DBDBDB;
      padding: 25px;
      height: 225px;
      align-items: center;
      h4 {
        margin: 0;
      }
    }
  }
}

.block-loader{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}