.menu.nav {
  padding: 0;
  display: flex;
  margin: 0;
  height: 100%;

  > li {
    position: relative;
    display: flex;
    align-items: center;


    > span,
    > a {
      font-size: inherit;
      line-height: 1.5;
      color: inherit;
      padding: 0rem 2rem;
      margin: 0;
      color: #fff;
      text-decoration: none;

      &:hover {
        color: $color-primary;
      }
    }

    @include less-than(1280px) {
      > span,
      > a {
        padding: 0rem 1rem;
      }
    }

    @include less-than($screen-lg) {
      font-size: 1.4rem;
      > span,
      > a {
        padding: 0.7rem;
      }
    }
  }

  /** Dropdown Menu */
  .dropdown {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
  }

  .dropdown-menu {
    > li {
      border-bottom: 1px dashed #EDEDED;
      padding: 5px 0;
      > a {
        display: inline-block;
        width: 100%;
        font-size: 16px;
        line-height: 1.5;
        color: #343434;
      }
    }
  }
}

/** Header Top Menu **/
.top-menu {
  .menu.nav > li > a {
    padding: 0 1rem;
  }
}


/** Header Main Menu, User Menu **/

.header-user,
.main-menu {
  .menu.nav {
    > li { 
      > a {
        font-weight: 700;
      }

      a.mm-item {
        display: flex;
        align-items: center;
        &::before {
          content: '';
          width: 33px;
          height: 33px;
          display: inline-block;
          background-repeat: no-repeat;
          margin-right: 2.5rem;
          flex-shrink: 0;
        }
        &.mm-car::before {
          background-image: url(../../images/icon-menu-car.png);
        }
        &.mm-com::before {
          background-image: url(../../images/icon-menu-com.png);
        }
        &.mm-ind::before {
          background-image: url(../../images/icon-menu-ind.png);
        }
        &.mm-bike::before {
          background-image: url(../../images/icon-menu-bike.png);
        }
        &.mm-oth::before {
          background-image: url(../../images/icon-menu-oth.png);
        }
        &.mm-scom::before {
          background-image: url(../../images/icon-menu-scom.png);
        }
        &.mm-mar::before {
          background-image: url(../../images/icon-menu-mar.png);
        }
        &.mm-access::before {
          background-image: url(../../images/icon-menu-access.png);
        }
      } 
    }

    /** Dropdown Menu */
    .dropdown {
      &:hover {
        > .dropdown-menu {
          display: block !important;
        }
      }
    }

    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      background-color: #fff;
      margin: 0;
      font-size: 1.7rem;
      font-weight: 400;
      list-style: none;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);      
      padding: 0 1rem;
      display: none;


      > li {
        > a {
          white-space: nowrap;
          padding: 1rem 2.5rem 1rem 0;
          
          &:hover {
            color: $color-primary;
          }
        }
      }
    }
  }

}

.main-menu {
  height: 5.6rem;
  .menu.nav {
    > li { 
      &:first-child > a {
        padding-left: 0;
      }
    }
  }
  .dropdown-menu {
    padding: 2.5rem 3rem;
    width: 79.5rem;
    column-count: 2;
    column-gap: 5rem;

  }
}