.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 16pt;
  border-collapse: collapse;
  border-spacing: 0;
  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
    text-align: left;
  }

  > tbody > tr {
    &:nth-of-type(odd) {
      background-color: #f9f9f9;
    }
    > td {
      padding: 8px;
      line-height: 1.6;
      vertical-align: top;
      border-top: 1px solid #ddd;
    }
  }
}