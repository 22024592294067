.view-accessories-list {
  .view-content {
    display: grid;
    grid-gap: 4rem 3rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin-bottom: 4rem;

    @include less-than($screen-xl) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include less-than($screen-md){
      grid-gap: 2rem;
    }

    .views-field {
      .field-content {
        display: inline-block;
        width: 100%;
        text-align: center;
        a {
          display: inline-block;
          width: 100%;
          font-weight: bold;
          word-break: break-word;
          img {
            width: 100%;
            object-fit: contain;
            margin-bottom: 1rem;
            height: 20rem;
            transition: transform 0.3s ease-in-out;
          }

          &:hover img {
            transform: scale(1.05);
          }
        }
        /** Responsive **/
        @include less-than($screen-md){
          padding: 0.5rem 0;
          a {
            img{
              height: 12rem;
            }
          }
        }

      }
    }
  }
}