
.form-group {
  margin-bottom: 15px;
}
.form-control {
  display: block;
  width: 100%;
  height: 3.6rem;
  padding: 6px 12px;
  font-size: 10.5pt;
  line-height: 1.6;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  outline: none;
  
}

textarea.form-control {
  height: auto;
}

.captcha.panel {
  border: none;
  padding: 0;
  margin: 1rem 0 2rem;
  .panel-heading {
    display: none;
  }
  .help-block {
    margin-bottom: 1.5rem;
  }
}
@include larger-than($screen-lg) {
  .webform-client-form .form-half {
      width: 48%;
      float: left;
      margin-right: 10px;
  }

  .webform-client-form textarea, .webform-client-form .grippie {
    width: 97%;
  }
}

/*------------------------------------*\
  File: Webform
\*------------------------------------*/
form.webform-client-form{

  .form-control,
  .form-select{
      &::placeholder{
          font-family: $base-font;
      }
      font-family: $base-font;
      height: auto;
      font-size: 1.6rem;
  }

}