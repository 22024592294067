//Fix loading issue
.slick__slider > .slick__slide {
  display: none;
}

.slick--skin--boxed-carousel {
  .slide__content,
  .slide__content:hover {
      border: none;
      border-radius: 0;
      transition: none;
      box-shadow: none;
  }
}

// Default
.slick {
  position: relative;
  .slick-slide {
      height: auto;
  }
  .slick__arrow {
        .slick-prev,
        .slick-next {
            font-size: 0;
            width: 50px;
            height: 83px;
            border: 0;
            position: absolute;
            padding: 0;
            top: 50%;
            transform: translateY(-50%);
            background-size: contain;
            background-repeat: no-repeat;
            &::before {
                content: none;
            }
            &:hover {
                opacity: 0.5;
            }
        }

      .slick-prev {
          background-image: url('../../images/icon-prev.svg');
          left: -70px;

      }
      .slick-next {
          background-image: url('../../images/icon-next.svg');
          right: -70px;
      }
  }

  img {
    margin: 0 auto;
  }
}

.slick-dots {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  li {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;

      button {
          font-size: 0;
          line-height: 0;
          display: block;
          width: 20px;
          height: 20px;
          padding: 5px;
          cursor: pointer;
          color: transparent;
          border: 0;
          outline: none;
          background: transparent;

          &::before {
              content: '';
              color: transparent;
              border: 1px solid rgb(155, 155, 155);
              border-radius: 50%;
              width: 16px;
              height: 16px;
              opacity: 0.5;
              position: absolute;
              top: 0px;
              left: 0px;
          }
      }
      &.slick-active button:before {
          background-color: #1c1c1c;
          border-color: #1c1c1c;
          color: transparent;
          opacity: 0.75;
      }
  }
}

// Products Carousel
.products-carousel.slick {
  > .slick__slide {
      max-width: Max(320px, 20%);
  }
  .slick-slider {
  }
  .slide__content {
      padding: 0 20px;
      display: flex;
      justify-content: center;
  }

  .slick__arrow {
      .slick-prev,
      .slick-next {
          width: 30px;
          height: 50px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
      }
      .slick-prev {
          left: -35px;
      }
      .slick-next {
          right: -35px;
      }

      // FIX slick arrows responsive display
      // Always show arrows container
      // Arrow buttons will be hidden when elements number less than a row
      // &.element-hidden {
      //     display: block;
      // }

  }

  .slick-dots {
      margin-bottom: 2rem;
  }
}

.slick.slick--view--homepage-brands {
    .slick-prev,
    .slick-next {
        width: 2.1rem;
        height: 3.4rem;
    }
  .slick__arrow {
    .slick-prev {
      left: 1rem;
      background-image: url('../../images/icon-prev.png');
    }
    .slick-next {
      right: 1rem;    
      background-image: url('../../images/icon-next.png');
    }
  }
}

