/*------------------------------------*\
    Component: Button
\*------------------------------------*/
@use 'sass:color';

@mixin button($background: $color-primary, $background-lighten: null) {
  font-size: 1.8rem;
  border: none;
  border-radius: 3px;
  font-weight: 700;
  transition: all 0.3s;
  display: inline-flex;
  min-width: 5rem;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: $background;
  line-height: 1.2;
  padding: 1.2rem 2.6rem;
  cursor: pointer;

  i,
  span.icon {
    margin-right: 1rem;
    font-size: 1.4rem;
  }

  &:focus,
  &:active,
  &:hover,
  &:active:focus {
    @if $background-lighten {
      background: $background-lighten;
    } @else {
      background: lighten($background, 7%);
    }
    color: #fff;
    text-decoration: none;
    outline: none;
  }

  @if $background == transparent {
    border: 1px solid #fff;
    &:focus,
    &:active,
    &:hover,
    &:active:focus {
      background: $color-primary;
      border-color: $color-primary;
      text-decoration: none;
      outline: none;
    }
  }

  @include less-than($screen-xl) {
    font-size: 1.6rem;
    padding: 1rem 3rem;
  }
  // @include less-than($screen-md) {
  //   font-size: 1.5rem;
  // }
}

@mixin btn-close {
  width: 14px;
  height: 14px;
  padding: 3px 3px;
  color: #000;
  background: transparent url(../../images/icon-close.svg) center/14px auto
    no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  cursor: pointer;
  display: inline-block;
}

.button-primary,
.btn-primary,
.button,
.btn {
  color: #000000;
  &:focus,
  &:active,
  &:hover,
  &:active:focus {
    color: #000000;
  }
  @include button($color-primary);
}

.btn-transparent {
  @include button(transparent);
}

.button-secondary,
.btn-secondary {
  font-size: 12.5px;
  padding: 9px 15px;
  border-radius: 5px;
  @include button($color-black);
}

.btn-default {
  @include button(#0c0c0c);
}

.btn-dark {
  @include button(#343434);
}

.btn-large {
  font-size: 2.2rem;
  font-weight: 900;
  padding: 1.2rem 2.6rem;

  @include less-than($screen-md) {
    font-size: 2rem;
    padding: 1.2rem 1rem;
  }
}

.btn-small {
  font-size: 1.8rem;
  font-weight: 700;
  padding: 0.6rem 3.5rem;
}

.btn-close {
  @include btn-close;
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  font-size: 15.75pt;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  float: right;
}
