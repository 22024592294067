@use 'sass:color';

.tw-container {
  max-width: $screen-xxl - 60px;
  margin: 0 auto;

  @include less-than($screen-xxl) {
    max-width: $screen-xl - 40px;
  }

  @include less-than($screen-xl) {
    max-width: $screen-lg - 30px;
  }

  @include less-than($screen-lg) {
    max-width: $screen-md - 20px;
  }

  @include less-than($screen-md) {
    max-width: $screen-sm - 10px;
  }

  @include less-than($screen-sm) {
    width: 100%;
    padding: 0 2rem;
  }
}

.hero {
  // margin-top: $header-height !important;
  // @include less-than($screen-lg) {
  //   margin-top: $header-height-mobile !important;
  // }
}

.content {
  position: relative;
}

.main-container {
  position: relative;
  display: flex;
  flex-direction: column;
  color: #3d3d3d;

  .main-content {
    display: flex;
    align-items: flex-start;
    max-width: 100%;

    .sidebar {
      width: 28rem;
      margin-right: 6rem;
      background: #0c0c0c;
      padding: 1.5rem 2.3rem;

      @include less-than($screen-xl) {
        margin-right: 2rem;
      }

      .sidebar-trigger {
        display: none;
      }

      h2 {
        font-weight: bold;
        font-size: 16px;
        line-height: 138.69%;
        color: #fdd100;
        margin: 0 0 1rem;
      }
      a {
        color: #fff;
      }

      .btn-default {
        background: linear-gradient(180deg, #2b2b2b 0%, #242424 100%);
        border-right: 1px solid #161616;
        margin-bottom: 2rem;
      }
    }

    .content-wrapper {
      display: flex;
      flex: 1;
      max-width: 100%;

      @include less-than($screen-lg) {
        flex-direction: column;
      }

      .region-content {
        flex: 1;
        max-width: 100%;
      }
    }

    @include less-than($screen-lg) {
      .sidebar {
        //height: calc(100vh - #{$header-height-mobile});
        //top: $header-height-mobile;
        position: fixed;
        width: 80vw !important;
        top: 0;
        height: 100vh;
        padding: 140px 2rem 2rem 2rem;
        left: 0;
        transition: all 0.5s;
        transform: translateX(-80vw);
        z-index: 99;

        .sidebar-trigger {
          display: inline-block;
          &::after {
            content: 'filter';
            position: absolute;
            right: -2rem;
            height: 8rem;
            width: 2rem;
            background: $color-primary;
            color: #fff;
            writing-mode: vertical-lr;
            text-align: center;
            line-height: 2rem;
            top: 0;
            text-transform: uppercase;
            font-size: 1.4rem;
            transform: translateY(10px);
            top: 50%;

            &:hover {
              background: lighten($color-primary, 5%);
            }
          }
        }

        .sidebar-wrapper {
          height: 100%;
          overflow-y: auto;
        }

        &.opened {
          box-shadow: 1px 1px 10px #aaa0a0;
          transform: translateX(0);

          .sidebar-trigger::after {
            content: 'close';
          }
        }
      }
    }
  }
}

.block-easy-breadcrumb {
  background: #f3f3f3;
  padding: 1rem 0;
  .easy-breadcrumb {
    @extend .tw-container;
    font-size: 14px;
    line-height: 138.69%;
    color: #9b9b9b;
    a {
      color: #0c0c0c;
    }
  }
}
