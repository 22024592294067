.view-technical {
  .views-row {
    margin-bottom: 3rem;
    
    &:not(:first-child){
      padding-top: 2rem;
      border-top: 1px dashed #ddd;
    }
    
    .node-technical-information.node-teaser {
      display: flex;
      align-items: center;

      .group-img {
        flex-basis: 16%;
        max-width: 30rem;
        padding-right: 3rem;
        .field-item{
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          max-width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      .group-descr {
        flex: 1;

        h2 {
          font-size: 2rem;
          margin-top: 0;
        }

        .field-name-node-link {
          a {
            display: flex;
            align-items: center;

            &::before {
              content: '';
              width: 9px;
              height: 17px;
              background-image: url(../../images/icon-arrow-back.png);
              background-repeat: no-repeat;
              margin-right: 8px;
              flex-shrink: 0;
            }
          }
        }
      }

      @include less-than($screen-lg) {
        flex-direction: column;
        .group-img,
        .group-descr {
          flex-basis: 100%;
        }
        .group-img{
          margin-bottom: 2rem;
        }
      }
    }
  }
}