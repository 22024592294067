.page-news {
  .view-news {
    margin-top: 2rem;
    margin-bottom: 4rem;
    .view-content {
      display: grid;
      grid-gap: 5rem 6.3rem;
      grid-template-columns: repeat(3, minmax(0, 1fr));

      @include less-than($screen-xxl) {
        grid-gap: 2rem;
      }

      @include less-than($screen-xl) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include less-than($screen-md) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }

    .node-teaser {
      display: flex;
      flex-direction: column;
      height: 100%;

      .field-name-field-image {
        margin-bottom: 1rem;
        a {
          display: inline-block;
          width: 100%;
          height: 28.6rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.3s ease-in-out;
          }

          &:hover img {
            transform: scale(1.05);
          }
        }
      }

      .field-name-title h2 {
        margin-bottom: 2rem;
        font-size: 18px;
        line-height: 1.2;
        font-weight: 700;

        a {
          color: #1E1E1E;

          &:hover {
            color: $color-primary;
          }
        }
      }

      .field-name-body {
        font-size: 16px;
        line-height: 149.19%;
        color: #484848;

        p {
          margin-top: 0;
        }
        
      }

      .field-name-node-link {
        a {
          display: flex;
          align-items: center;

          &::before {
            content: '';
            width: 9px;
            height: 17px;
            background-image: url(../../images/icon-arrow-back.png);
            background-repeat: no-repeat;
            margin-right: 8px;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}

.node-type-article.page-node {
  .view-mode-full {
    .field-type-image {
      margin-top: 2rem;
      img {
        width: 100%;
        height: 32rem;
        object-fit: cover;
      }
    }

    .field-name-body {
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 149.19%;
        color: #484848;
      }

      h2 {
        font-size: 3rem;
      }
      
      h3 {
        font-size: 2rem;
      }
    }

    .news-return-link {
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      color: $color-primary;
      margin-top: 1rem;
      display: inline-block;
    }
  }

  .block-views {
    width: 42.3rem;
    margin-left: 12.5rem;
    margin-bottom: 4rem;

    @include less-than($screen-xl) {
      margin-left: 2rem;
    }

    @include less-than($screen-lg) {
      margin-left: 0;
    }

    h2.block-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 138.69%;
      color: #222222;

    }
    .news-side-links {
      .view-content {
        .views-field {
          padding: 1rem 0;
          border-bottom: 1px dashed #EFEFEF;

          a {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.5;
            color: #0C0C0C;
            display: flex;
            align-items: center;

            &::before {
              content: '';
              width: 9px;
              height: 17px;
              background-image: url(../../images/icon-arrow-back.png);
              background-repeat: no-repeat;
              margin-right: 18px;
              flex-shrink: 0;
              transition: transform .5s ease-in-out;
            }

            &:hover::before {
              transform: translateX(9px);
            }
          }
        }
      }
    }
  }
}
