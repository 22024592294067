@use 'sass:color';

.front.page-node {
  .region-content article {
    header,
    footer {
      display: none;
    }
  }

  .page-header {
    display: none;
  }

  .home-slider {
    .node-slider {
      --hero-height: 60rem;
      height: var(--hero-height);

      .slide__content {
        position: relative;
        height: 60rem;
      }

      .field-name-remote-image-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;

        img {
          width: 100%;
          height: var(--hero-height);
          object-fit: cover;
        }

        @include less-than($screen-md) {
          display: none;
        }
      }

      .field-name-remote-image-link-mobile,
      .field-name-field-slider-mobile-image {
        display: none;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }

        @include less-than($screen-md) {
          display: block;
        }
      }

      .group-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @include less-than($screen-md) {
        height: auto;
      }
    }
  }

  .view-homepage-block-menu {
    display: flex;
    color: #fff;

    @include less-than($screen-lg) {
      flex-direction: column;
    }

    .view-header {
      flex-basis: 25%;
      background-color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.5rem;

      .home-menu-header {
        font-weight: 900;
        font-size: 3.2rem;
        line-height: 113.19%;
        text-transform: uppercase;
        max-width: 27rem;

        span {
          color: $color-primary;
        }

        @include less-than($screen-xxl) {
          font-size: 2.5rem;
        }

        @include less-than($screen-xl) {
          font-size: 2.2rem;
        }
      }
    }

    .view-content {
      display: flex;
      flex: 1;

      @include less-than($screen-lg) {
        flex-direction: column;
      }

      .views-row {
        flex: 1;
        border-left: 1px dashed rgba(255, 255, 255, 0.3);
        background-color: #1e1e1e;

        .views-field {
          display: flex;
          width: 100%;
          height: 100%;

          > span {
            width: 100%;
          }
        }

        .home-menu-link {
          font-size: 2rem;
          line-height: 122.69%;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 3rem 1.5rem 2rem;
          height: 100%;
          @include animated;

          &:hover {
            background: lighten(#1e1e1e, 10%);
          }

          @include less-than($screen-xl) {
            font-size: 1.6rem;
            padding: 1.5rem;
          }

          @include less-than($screen-lg) {
            font-size: 1.3rem;
          }

          > div {
            max-width: 19.5rem;

            @include less-than($screen-lg) {
              width: 14.5rem;
            }
          }

          .home-menu-image {
            margin-right: 2.5rem;
            text-align: right;

            @include less-than($screen-xxl) {
              margin-right: 1.5rem;
            }

            @include less-than($screen-xl) {
              img {
                max-width: 8rem;
                object-fit: contain;
              }
            }

            @include less-than($screen-lg) {
              margin-right: 2.5rem;

              img {
                max-width: 7.3rem;
                max-height: 6rem;
              }
            }
          }

          .home-menu-subtitle {
            margin-top: 1rem;
          }

          .home-menu-title {
            font-size: 3.1rem;
            font-weight: 700;
            margin: 0.5rem 0;

            @include less-than($screen-xxl) {
              font-size: 2.5rem;
            }

            @include less-than($screen-xl) {
              font-size: 2rem;
            }
          }

          .home-menu-icon {
            width: 3.6rem;
            height: 3.6rem;
            background-image: url(../../images/icon-arrow-right.png);
            background-repeat: no-repeat;
            margin-left: 2rem;
            flex-shrink: 0;

            @include less-than($screen-xxl) {
              margin-left: 1rem;
            }

            @include less-than($screen-xl) {
              width: 2.6rem;
              height: 2.6rem;
              background-size: 2.6rem auto;
            }
          }
        }

        &:last-child {
          .home-menu-link .home-menu-image img {
            @include less-than($screen-lg) {
              width: 6rem;
              height: 6rem;
            }
          }
        }
      }
    }
  }

  .view-homepage-brands {
    margin-top: 4rem;

    .view-content {
      ul.slide__content {
        display: flex;
        list-style: none;
        border: none;
        box-shadow: none;
        flex-wrap: wrap;

        li {
          min-width: 0;
          width: 16.666666%;
          margin: 0;
          padding: 0;
          height: 100px;

          img {
            max-width: 90%;
            margin: 0 auto;
            height: 100%;
          }

          @include less-than($screen-lg) {
            width: 50%;
          }

          @include less-than($screen-md) {
            width: 100%;
          }
        }
      }
    }
  }

  .home-features {
    margin-top: 3.5rem;

    @include less-than($screen-lg) {
      margin-top: 1rem;
    }

    ul {
      display: flex;
      list-style: none;
      background-color: #0f0f0f;
      padding: 2rem;
      color: #fff;
      font-style: italic;
      font-weight: 700;
      font-size: 2rem;
      line-height: 113.19%;

      @include less-than($screen-xl) {
        font-size: 1.8rem;
      }

      @include less-than($screen-lg) {
        flex-direction: column;
        align-items: flex-start;
      }

      li {
        flex: 1 1 auto;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1.5rem;

        &:not(:last-child) {
          border-right: 1px solid #535353;

          @include less-than($screen-lg) {
            border-right: none;
            margin: 1rem 0;
          }
        }

        &::before {
          content: '';
          background-image: url(../../images/icon-warranty.png);
          background-position: center;
          background-repeat: no-repeat;
          width: 38px;
          height: 38px;
          margin-right: 1rem;
          flex-shrink: 0;

          @include less-than($screen-xl) {
            width: 33px;
            height: 33px;
            margin-bottom: 1rem;
            background-size: 33px auto;
          }
        }

        &.home-features__nationwide::before {
          background-image: url(../../images/icon-nationwide.png);
        }

        &.home-features__collect::before {
          background-image: url(../../images/icon-collect.png);
          width: 38.5px;
        }

        &.home-features__fit::before {
          background-image: url(../../images/icon-fit.png);
        }

        &.home-features__ship::before {
          background-image: url(../../images/icon-ship.png);
        }
      }
    }
  }

  .region-blockgroup-home-content-two-col {
    display: flex;
    margin-top: 4rem;

    @include less-than($screen-lg) {
      flex-direction: column;
      margin-top: 1rem;
    }

    .home-facebook {
      margin-right: 13rem;
      flex-basis: 90rem;
      text-align: center;
      flex: 1;

      iframe {
        max-width: 100%;
      }

      @include less-than($screen-xl) {
        margin-right: 5rem;
      }

      @include less-than($screen-lg) {
        margin-right: 0;
      }
    }

    .home-faqs {
      flex: 1;

      h2.block-title {
        font-weight: 700;
        font-size: 25px;
        line-height: 120%;
        color: #2e2e2e;
      }

      .view-faqs {
        .view-content {
          border-bottom: 1px dashed #efefef;

          .field-name-title {
            border-top: 1px dashed #efefef;
            padding: 10px 0;

            h2 {
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              display: flex;
              margin: 0;
              align-items: center;
              color: $color-black;
              font-weight: 700;
              cursor: pointer;

              &::before {
                content: '';
                width: 9px;
                height: 9px;
                border-style: solid;
                border-color: #fdd100;
                border-width: 0 3px 3px 0;
                transform: rotate(-45deg);
                margin-right: 13px;
              }
            }
          }

          .field-name-body {
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.423823px;
            color: #343434;
            display: none;
            margin-bottom: 1rem;

            p {
              margin-top: 0;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        .footer-link {
          font-weight: bold;
          font-size: 16px;
          line-height: 276.19%;

          a {
            display: flex;
            align-items: center;

            &::after {
              content: '';
              background-image: url(../../images/icon-arrow-right-black.png);
              background-repeat: no-repeat;
              width: 14px;
              height: 16px;
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }

  .home-store-finder {
    @include extendbg(
      url(../../images/background-home-store-finder.png) no-repeat center/cover
    );
    margin-top: 6rem;

    p {
      margin: 0;
    }

    .btn {
      width: 20rem;
    }

    @include less-than($screen-sm) {
      * {
        text-align: center;
      }

      .btn {
        width: 100%;
      }
    }

    &__wrapper {
      max-width: 91rem;
      display: flex;
      align-items: center;
      margin: 0 auto;

      img {
        margin: 0.7rem 0;

        @include less-than($screen-md) {
          display: none;
        }
      }
    }

    &__content {
      flex: 1;
      color: #ffffff;
      line-height: 138.69%;

      h3 {
        font-weight: 700;
        font-size: 3rem;
        line-height: 3rem;

        @include less-than($screen-xl) {
          font-size: 2.6rem;
        }
      }

      p {
        font-style: italic;
        font-weight: 400;
        font-size: 2rem;
        margin-top: 2.5rem;
        margin-bottom: 3rem;

        @include less-than($screen-xl) {
          font-size: 1.8rem;
        }
      }
    }

    &__form {
      display: flex;

      input,
      button {
        height: 5.5rem;
        margin-bottom: 1rem;
      }

      @include less-than($screen-md) {
        flex-direction: column;
      }
    }

    &__input.form-control {
      margin-right: 1.5rem;
      height: 5.5rem;
      border: 1px solid #cecece;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
      font-size: 2rem;
    }

    &__search-btn {
    }
  }
}
