.page-node.node-type-store-location {
  .node-store-location {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .group-descr {
      flex-basis: 65%;
    }

    .group-map {
      flex-basis: 30%;
    }

    @include less-than($screen-lg) {
      flex-direction: column;

      >div {
        flex-basis: 100%;
        margin-bottom: 5rem;
      }
    }

    .group-contact-details {
      background: #FFFDE8;
      border: 1px solid #EFCC4E;
      padding: 2rem;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 198.19%;
      color: #232323;

      h3 {
        display: none;
      }

      .field {
        display: flex;

        .field-label {
          width: 100px;
        }
      }

      .field.field-type-addressfield {
        .field-item {
          display: flex;

          div {
            display: flex;
          }

          span,
          div {
            margin-right: 3px;
          }

          @include less-than($screen-md) {
            flex-direction: column;
          }
        }
      }
    }

    .block-webform {
      margin-top: 4rem;

      h2 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 138.69%;
        color: #222222;
      }

      form>div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .form-item {
          flex-basis: 100%;

          &:nth-child(1),
          &:nth-child(2) {
            flex-basis: 48%;

            @include less-than($screen-md) {
              flex-basis: 100%;
            }
          }
        }
      }

      .form-item {
        label {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 151.69%;
          color: #2E2E2E;
          margin-bottom: 1rem;
        }

        input,
        textarea {
          background: #FFFFFF;
          border: 1px solid #D6D6D6;
          box-sizing: border-box;
          border-radius: 1.7737px;
          width: 100%;
        }
      }
    }

    .group-map {
      .geolocation-map {
        margin: 0;
      }

      .group-services-offered,
      .group-open-hours {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #484848;
        border-top: 2px solid #FDD100;
        margin-top: 2rem;
        padding-top: 2rem;

        h3 {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          margin: 0;
          color: #222222;
          margin-bottom: 1.5rem;
        }

        ul {
          list-style: none;

          li {
            margin-bottom: 0.5rem;
          }
        }

        .field-name-field-store-open-hours {
          p {
            display: flex;
            margin: 0;
            margin-bottom: 0.5rem;

            br {
              display: none;

            }
          }

          strong {
            width: 100px;
          }

          table {
            tr {
              td {
                padding-bottom: 0.5rem;

                strong {
                  padding-right: 2rem;
                }
              }
            }
          }
        }
        .services_col2 {
          columns: 2;
          @include less-than ($screen-md) {
            columns: unset;
          }
        }
      }
    }
  }
}