.plate-search-btn {
  border: 1px solid #ffc829;
  background: #ffc829;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px 12px;
  font-size: 9.5pt;
  font-weight: bold;
  float: right;
  border-radius: 4px;
  margin-bottom: 1rem;

  &.feedback {
    background-color: #1387ac;
    border-color: #1387ac;
    color: #fff;
  }

  @include less-than($screen-lg) {
    float: left;
  }
}

.node-type-vehicle-search .page-header {
  margin-bottom: 1rem;
}

.vehicle-search__menu-buttons {
  margin-bottom: 1rem;

  > a {
    &:focus,
    &:active,
    &:hover,
    &:active:focus,
    &.active {
      background-color: #000;
      color: $color-primary;
      border-color: #000;
    }
  }
}

/** Vehicle Search **/
.react-search-block {
  .react-vehicle-search {
    display: flex;

    @include less-than($screen-lg) {
      flex-direction: column;
    }

    > div {
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 5px;
      flex: 1;

      .vs-category-heading {
        display: block;
        text-transform: uppercase;
        font-weight: 700;
      }

      .vs-select-list {
        select {
          width: 100%;
          background: none;
          border: none;

          &:focus {
            border: none;
            outline: none;
          }
        }
      }

      .vs-category-filter {
        .help-block,
        .control-label {
          display: none;
        }

        .form-group {
          margin: 1rem 0;
          position: relative;

          .form-control {
            background: #f3f3f3;
            border-color: #f3f3f3;
            border-style: solid;
            box-shadow: none;
            outline: none;
            height: 36px;
            width: 100%;
            box-sizing: border-box;
            border-radius: 4px;
          }

          &:after {
            position: absolute;
            content: '\f002';
            font-family: $font-awesome;
            font-size: 20px;
            color: #a6a6a6;
            right: 1rem;
            bottom: 0.8rem;
            font-size: 1.8rem;
          }
        }
      }
    }

    .vs-category-greyed {
      background: #353535;
      border: 1px solid #4e4e4e;
      border-radius: 3px;
      color: #fff;

      .vs-select-list,
      .vs-category-filter {
        display: none;
      }

      @include larger-than($screen-lg) {
        background: none;
        border: none;
        color: #0c0c0c;

        .vs-select-list,
        .vs-category-filter {
          display: block;
        }
      }
    }

    .vs-category-selected {
      .vs-select-list,
      .vs-category-filter {
        display: none;
      }

      @include larger-than($screen-lg) {
        background: none;

        .vs-select-list,
        .vs-category-filter {
          display: block;
        }
      }

      @include less-than($screen-lg) {
        background: #353535;
        color: $color-primary;
      }
    }

    .vs-category-open-selected {
      background: #fdd100;

      @include larger-than($screen-lg) {
        background: none;
      }
    }

    .vs-category-open {
      background: #353535;
      border: 1px solid #4e4e4e;
      border-radius: 3px;
      color: #fff;

      .vs-select-option {
        padding: 3px 0;
      }

      @include larger-than($screen-lg) {
        background: none;
        border: none;
        color: #0c0c0c;

        .vs-select-list,
        .vs-category-filter {
          display: block;
        }
      }

      @include less-than($screen-lg) {
        .vs-select-option {
          color: #fff;
        }
      }
    }

    .vs-category-heading {
      .vs-category-heading-static {
        display: block;

        @include less-than($screen-lg) {
          display: none;
        }
      }

      .vs-category-heading-changing {
        display: block;

        @include larger-than($screen-lg) {
          display: none;
        }
      }
    }
  }
}

.vs-btn-clear {
  button,
  button:hover,
  button:active,
  button:active:focus {
    color: #dbb500;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    text-decoration: underline;
    padding: 0;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 138.69%;
  }
}

.vs-selected-vehicle-name {
  background: #729829;
  color: #fff;
  padding: 1rem;
  margin: 3rem 0;
  border-radius: 0.5rem;
  font-weight: 400;
  font-style: italic;

  > span {
    padding-right: 1.5rem;
    margin-right: 0;

    label {
      padding-right: 1rem;
    }
  }
}

.vs-results-table {
  display: grid;
  grid-gap: 6.3rem 0rem;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  margin-bottom: 4rem;

  @include less-than($screen-xl) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include less-than($screen-lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include less-than($screen-sm) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .vs-results-header-drupal {
    display: none;
  }

  .vs-results-row {
    .vs-results-parent {
      border-right: 1px dashed #b0b0b0;
      padding: 0 2.5rem;

      > div {
        display: flex;
        flex-direction: column;
      }

      .vs-results-pic {
        margin-bottom: 1.5rem;
        display: flex;
        justify-content: center;

        img {
          width: 225px;
          height: 225px;
          object-fit: contain;
          transition: transform 0.5s;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .vs-results-partnum {
        font-style: italic;
        font-weight: 900;
        font-size: 2.2rem;
        line-height: 138.69%;
        color: #343434;
      }

      .vs-results-price {
        display: none;
        text-transform: uppercase;
        color: #3c3c3c;
        font-weight: 700;
        font-size: 2rem;
        line-height: 164.69%;
        align-self: flex-start;

        .price {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .price-suffix {
          font-size: 12px;
          color: #888;
          font-weight: normal;
          line-height: 1;
        }

        .vs-price-customer-price {
          display: block !important;
        }
      }

      .vs-results-control {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
      }

      .vs-results-stock {
        display: none;
      }

      .vs-results-note {
        font-weight: 400;
        font-size: 1.4rem;
        color: #343434;
        margin: 14px 0;
        min-height: 17.5px;
      }

      .vs-results-attribute {
        font-size: 14px;
        display: flex;
        align-items: center;

        label {
          font-weight: 700;
        }

        &:before {
          content: '\f111';
          font-family: 'FontAwesome';
          font-size: 8px;
          margin-right: 8px;
        }
      }

      .vs-results-addtocart {
        width: 100%;
        button {
          @extend .btn;
          font-weight: bold;
          font-size: 1.8rem;
          line-height: 151.69%;
          color: #fff;
          min-width: 100%;
        }
      }
    }
  }
}

// .vs-results-table{
// 	table{
// 		width:100%;
// 		tr{
// 			border-bottom:1px solid $color-gray-lighter;
// 		}
// 		.thead{
// 			border-bottom:2px solid $color-gray-lighter;
// 			th{
// 				padding:0.25rem 0.5rem;
// 			}
// 		}
// 		td{
// 			vertical-align: middle;
// 			padding: 0.5rem 1rem;
// 			img{
// 				width:100px;
// 				height:100px;
// 				object-fit:contain;

// 			}
// 		}
// 	}
// 	.vs-results-header-drupal{
// 		display: none;
// 		border-bottom:2px solid $color-gray-lighter;
// 		padding:0.5rem 1rem;
// 		font-weight: 700;
// 		text-transform: uppercase;
// 		>div{
// 			display: inline-block;
// 		}
// 		@include larger-than($screen-lg){
// 			display: block;
// 		}
// 	}
// 	.vs-results-row{
// 		border-bottom: 0.5rem solid $color-gray-more-light;
// 		&:nth-child(even){
// 			background: #fff;
// 		}
// 		&:nth-child(odd){
// 			background: $color-gray-lighter;
// 		}
// 		>div{
// 			display: block;
// 			>div{
// 				vertical-align: middle;
// 				>div{
// 					display: inline-block;
// 					vertical-align: middle;
// 					padding: 1rem 1rem 1rem 0;
// 					img{
// 						width:100px;
// 						height:100px;
// 						object-fit:contain;

// 					}
// 				}
// 			}
// 		}
// 	}
// 	.vs-results-addtocart,
// 	.vs-results-note{
// 		width:100%;
// 	}
// 	.vs-results-pic{
// 		cursor: pointer;
// 	}

// 	.vs-results-addtocart {
// 		button {
// 			@extend .btn;
// 			font-weight: bold;
// 			font-size: 1.4rem;
// 			line-height: 1.2;
// 			padding: 1.2rem 1.6rem;
// 			color: #0C0C0C;
// 		}
// 	}
// 	@include larger-than($screen-lg){
// 		.vs-results-pic{
// 			width:15%;
// 		}
// 		.vs-results-partnum{
// 			width:15%;
// 		}
// 		.vs-results-note{
// 			width:25%;
// 		}
// 		.vs-results-price{
// 			width:15%;
// 		}
// 		.vs-results-stock{
// 			width:15%;
// 		}
// 		.vs-results-addtocart{
// 			width:15%;
// 		}
// 	}
// }

/** Plate Number Search **/
.react-plate-search {
  display: flex;
  width: 47rem;
  max-width: 100%;
  margin-top: 4rem;

  @include less-than($screen-md) {
    width: auto;
  }

  .generic-text-input {
    flex: 1;
    min-width: 20rem;

    .form-control {
      width: 100%;
    }
  }

  #edit-clear,
  #run-plate-search {
    margin-bottom: 0;
    cursor: pointer;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 1.4rem;
    line-height: 1.6;
    transition: all 300ms ease;
    height: 36px;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid $color-primary;
    margin-left: 1rem;
    background: $color-primary;
  }

  #edit-clear {
    background-color: #fff;
    border-color: #ccc;
    font-weight: 400;
  }
}

.vs-plate-possiblity {
  td {
    padding: 0.15rem 0;
    border-bottom: 1px solid $color-gray-lighter;

    a {
      cursor: pointer;
      color: #1387ac;
      text-decoration: none;
    }
  }
}

/** Menu Popup Search **/
.header-menu-search-popup {
  .react-plate-search {
    margin-top: 0;

    .form-group {
      margin-bottom: 0;
    }
  }
}

.vs-plate-possiblity {
  a {
    span {
      margin-right: 1rem;
      font-weight: normal;
    }
  }

  &.selected {
    td {
      border-color: #1387ac;
    }
  }
}

.generic-modal {
  .modal-content {
    padding: 2rem;
  }

  .modal-header,
  .modal-footer {
    border: none;
    padding: 0;

    button:not(.close) {
      color: #fdd100;
    }

    button.close {
      font-size: 35px;
      font-weight: normal;
    }

    h4 {
      font-size: 20px;
      display: flex;
      align-items: center;

      &:before {
        content: ' ';
        background-image: url('/sites/all/themes/kodaweb/images/alert-icon.svg');
        background-size: contain;
        display: block;
        width: 30px;
        height: 30px;
        margin-right: 1rem;
      }
    }
  }

  .modal-body {
    border: 1px solid #fedb39;
    background: #fef4c6;
    margin: 2rem 0;
    line-height: 1.5;
  }
}

.vs-select-list {
  height: 300px;
  overflow-y: auto;
}

.vs-select-option {
  font-size: 14px;
  color: #555;
  line-height: 1.4;

  &:hover,
  &.selected {
    background: #ddd;
    color: #222;
    cursor: pointer;
  }
}

.mega-menu-search {
  &:not(.mega-menu-search__plate-search) {
    .react-plate-search {
      display: none;
    }
  }
}
