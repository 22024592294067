/*------------------------------------*\
    Basic Mixins
\*------------------------------------*/
@mixin link {
  color: #4d87ca;
  transition: color 0.3s;

  &:hover {
    color: lighten(#4d87ca, 20%);
  }
}

@mixin grid-layout($cols, $gap) {
  display: grid;
  grid-gap: $gap;
  grid-template-columns: repeat($cols, minmax(0, 1fr));
}

@mixin squareImgBox {
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  box-sizing: border-box;
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin less-than($size) {
  @media screen and (max-width: $size - 1px) {
    @content;
  }
}

@mixin larger-than($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}

@mixin wrapper($position: relative, $width: auto, $height: auto) {
  position: $position;
  width: $width;
  height: $height;
}

@mixin animated($duration: 300ms) {
  transition: all 300ms ease;
  -webkit-transition: all $duration ease;
  -moz-transition: all $duration ease;
  -ms-transition: all $duration ease;
  -o-transition: all $duration ease;
}

@mixin imgZoomHover {
  @include animated;
  overflow: hidden;
  position: relative;
  display: block;

  img {
    @include animated(1000ms);
  }
  &:hover {
    img {
      @include scale(1.09);
    }
  }
}

@mixin scale($factor) {
  -webkit-transform: scale($factor);
  -moz-transform: scale($factor);
  -ms-transform: scale($factor);
  -o-transform: scale($factor);
}

/*------------------------------------*\
  Extend Background 100%
\*------------------------------------*/

@mixin extendbg($bgcolor, $borderTopColor: none, $borderBottomColor: none) {
  // background: $bgcolor;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    display: block;
    height: calc(100% + 2px);
    top: -1px;
    width: 100vw;
    left: calc(0px - (100vw - 100%) / 2);
    background: $bgcolor;
    z-index: -1;
    visibility: visible;
    @if $borderTopColor != none {
      border-top: 1px solid $borderTopColor;
    }

    @if $borderBottomColor != none {
      border-bottom: 1px solid $borderBottomColor;
    }
  }
}

/*------------------------------------*\
  Hide Element
\*------------------------------------*/
@mixin invisible() {
  position: absolute;
  height: 1px;
  // width: 1px; // slider will initalize with no width
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  opacity: 0;
}
