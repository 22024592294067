/*------------------------------------*\
    Base
\*------------------------------------*/

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  margin: 0 !important;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}
