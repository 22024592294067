/*------------------------------------*\
  Slide Menu
\*------------------------------------*/
.slide-menu {
  $title-height:7.5rem;
  position: fixed;
  width: 80%;
  height: 100vh;
  background-color: #0C0C0C;
  padding-top: $title-height;

  left: -100%;
  top: 0;
  z-index: 1040;
  transition: all 300ms ease;
  
  &--site-title {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: $title-height
    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: #fff;
      margin: 0;
    }

    .hamburger-menu {
      .hamburger-menu.is-active .hamburger-inner,
      .hamburger-menu.is-active .hamburger-inner::before,
      .hamburger-menu.is-active .hamburger-inner::after {
        background-color: #fff;
      }
    }
  }


  @include larger-than($screen-lg) {
    display: none;
  }

  &.open {
    left: 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }

  .region-mobile-menu {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    height: calc(100vh - #{$title-height} );
    overflow: auto;

    .block-title {
      display: none;
    }

    .mobile-menu-links {
      margin-top: 1rem;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding: 1rem 0;

      .store-finder-links {
        margin: 0;
        display: flex;
        flex-direction: column;
        a {
          display: flex;
          align-items: center;
          font-weight: 700;
          font-weight: 2rem;
          color: #fff;
          font-size: 20px;
          line-height: 138.69%;
          padding: 1rem 0;

          img {
            margin-right: 1.5rem;
            width: 2.4rem;
            object-fit: contain;
          }

          &.mobile-trade-login{
            &::before{
              content: "";
              width: 10px;
              height: 20px;
              transform: skew(-30deg);
              background: $color-yellow; //#b72525;
              margin-right: 1.8rem;
              margin-left: 1rem;
            }
          }

        }
      }
    }

    .mobile-menu-main {
    }

    .menu.nav {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      li {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > a {
          width: 100%;
          font-size: 20px;
          line-height: 242.19%;
          display: flex;
          align-items: center;
          color: #FFF;
          &:focus,
          &:hover{
            color: #FFF;
          }
        }

      }

      .dropdown {
        > a .caret::after {
          content: '\f078';
          font-family: $font-awesome;
          font-weight: 100;
          font-size: 1.2rem;
          display: inline-block;
          margin-left: 1rem;
        }
        > a.expanded .caret::after {
          content: '\f077';
        }
      }

      .dropdown-menu {
        position: relative;
        box-shadow: none;
        padding-left: 2rem;
        flex-direction: column;
        display: none;

        &.open {
          display: flex;
        }
        > li {
          border-bottom: none;
          > a {
            font-size: 16px;
            line-height: 1.5;
            color: #FFF;
            //color: #FDD100;
            //line-height: 238.5%;
          }
          &.active{
            > a{
              color: #FDD100;
            }
          }
        }
      }
    }
  }

}
