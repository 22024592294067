.store-list-font {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #6b6b6b;
}

.header-top .store-locator,
.page-cart-checkout .store-locator {
  height: 40px;
  position: relative;
  margin-right: 1rem;

  &__display {
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;

    span {
      padding: 0 5px;
    }

    i {
      color: #fdd100;
    }
  }

  &__popup {
    position: absolute;
    width: 320px;
    top: 40px;
    z-index: 999;
    background: #fff;
    color: #222;
    padding: 1.5rem;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0px 1px 1px #ddd;
    transition: all 0.3s;
    // opacity: 0;
    // visibility: hidden;

    &--tabs {
      border-bottom: 1px solid #e6e6e6;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      padding-bottom: 1rem;
      margin-bottom: 1rem;

      span {
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 1rem;
        cursor: pointer;

        &:hover,
        &.active {
          border-color: #fdd100;
          border-width: 2px;
          color: #000;
        }

        &:first-child {
          margin-right: 2rem;
        }
      }
    }

    .my-store-detailed-info {
      border-bottom: 1px dashed #e6e6e6;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      display: flex;
      flex-direction: column;

      span {
        margin-bottom: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #222222;

        &:first-child {
          font-size: 14px;
        }
      }
    }

    .my-store-open-hrs {
      table {
        margin-top: 1rem;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 166.69%;
        color: #222222;

        td {
          padding-right: 5px;
        }
      }
    }
  }
}

.store-locator__popup--content.choose-store {
  background: #fff;
  flex-wrap: wrap;
  margin-top: 2rem;

  .address-search {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    i {
      font-size: 20px;
    }

    input {
      background: #ffffff;
      border: none;
      box-sizing: border-box;
      padding: 1rem;
      flex: 1;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;

      @include less-than($screen-md) {
        font-size: 1.8rem;
        width: 80%;
      }

      &::placeholder {
        color: #969696;
      }

      &:focus-visible {
        outline: none;
      }
    }

    a {
      color: #ddd;
    }
  }

  .my-store-list {
    width: 100%;
    height: auto;
    max-height: 175px;
    overflow-y: auto;
    overflow-x: hidden;
    //margin-top: 1rem;

    .my-store-item {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
      align-items: center;

      &.js-hide {
        display: none;
      }

      p {
        margin: 0;
        margin-top: 5px;
      }

      b {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
      }

      @include less-than($screen-md) {
        b {
          font-size: 16px;
        }
      }

      .my-store-item-details {
        > b ~ * {
          display: none;
        }

        > p {
          display: none;

          &:nth-child(2) {
            display: flex;
            align-items: center;
            @extend .store-list-font;

            strong {
              margin-right: 1rem;
            }

            br {
              display: none;
            }
          }
        }
      }

      ul {
        li {
          display: none;

          &:first-child {
            display: flex;
            @extend .store-list-font;
            margin-top: 5px;
          }
        }
      }

      table {
        @extend .store-list-font;
        margin-top: 5px;
        width: 100% !important;

        tr {
          display: none;

          td {
            padding-right: 5px;

            p:not(:last-child) {
              display: none;
            }
          }

          &:first-child {
            display: block;
          }
        }
      }

      .my-store-select {
        border: 1px solid #cacaca;
        background: #fdd100;
        border-radius: 5px;
        height: auto;
        font-weight: 500;
        font-size: 12px;
        color: #222222;
        padding: 3px 8px;
        margin-right: 1rem;
        cursor: pointer;
      }

      &.store-selected {
        .my-store-select {
          color: #fdd100;
          background: #222;
          pointer-events: none;

          &:after {
            content: 'ed';
          }
        }
      }
    }
  }
}

.page-node.node-type-product {
  .add-to-cart {
    form {
      display: none;
      margin-top: 2rem;

      .product-extra {
        display: none;
      }
    }
  }

  .show-on-selected {
    display: none;
  }

  .with-store .show-on-unselected {
    display: none;
  }

  .with-store {
    .show-on-selected {
      display: block;
    }

    & + form,
    & + .field-name-product-enquiry-button {
      display: block !important;
    }
  }

  .store-locator-tip {
    background-color: #fdd100;
    padding: 2rem;
    //margin-bottom: 2rem;
    font-size: 18px;
    line-height: 1.5;

    span {
      font-weight: bold;
    }

    @include less-than($screen-md) {
      font-size: 1.6rem;
    }
  }

  .store-locator__popup--content.my-store,
  .store-locator__display,
  .store-locator__popup--tabs {
    display: none;
  }

  .store-locator__popup {
    display: block;
  }

  .store-locator__popup--content.choose-store {
    padding: 5px 2rem;
    border: 2px solid #ddd;
    border-radius: 3px;
    display: flex;
    align-items: center;

    @include less-than($screen-md) {
      padding: 5px 10px;
    }
  }
}

.page-cart-checkout {
  #store-selected {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    border-bottom: 2px solid #ddd;

    > span {
      font-size: 14px;
      font-weight: bold;
      display: inline-block;
      margin-right: 1rem;
    }
  }

  .form-item.form-item-panes-uc-form-field-battery-town-selected-store {
    display: none;
  }
}

.store-locator__popup--contents {
  &.locator-modal {
  }
}
