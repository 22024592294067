@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@mixin fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 1s;
}