/*------------------------------------*\
    Components: alert
\*------------------------------------*/

.alert {
  background: $color-primary;
  border-radius: 3px;
  padding: 1rem;
  color: #000;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  z-index: 90;
  border: 1px solid lighten($color-primary, 10%);
  position: relative;

  * {
    margin-right: 4px;
    margin-left: 4px;
  }

  ul {
    margin-left: 2rem;
  }

  li {
    list-style-type: disc;
  }

  a.close {
    position: absolute;
    right: 1rem;
    font-size: 2rem;
    transition: all 0.3s;

    &:hover {
      color: #000;
    }
  }

  &.alert-success {
    background-color: #dbf4a5;
    color: #323a2d;
    border: 1px solid lighten(#dbf4a5, 20%);

    a:not(.close) {
      font-weight: bold;
    }
  }
  &.alert-danger {
    background-color: $color-danger;
    border: 1px solid lighten($color-danger, 20%);
  }
  &.alert-warning {
    background-color: $color-warning;
    border: 1.5px solid lighten($color-warning, 20%);
  }
}
