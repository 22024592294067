ul.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 4rem;

  li {
    span,
    a {
      border-bottom: 2px solid transparent;
      display: inline-block;
      padding: 6px 10px;
      font-size: 14px;
      line-height: 125.7%;
      color: #343434;

      &:hover,
      &:focus {
        border-bottom-color: $color-primary;
      }

    }
  
    &.active span {
      border-bottom-color: $color-primary;
    }

    &.pager-first,
    &.pager-last {
      display: none;
    }
  }
}