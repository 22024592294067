/*------------------------------------*\
 Brands
\*------------------------------------*/
.brands-block {
  h2.block-title {
    font-size: 18px;
    margin-top: 40px;
    background: #eee;
    padding: 10px;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
  }
  .view-brands-page-block {
    .view-content {
      display: grid;
      grid-gap: 0;
      grid-template-columns: repeat(4, minmax(0, 1fr));

      @include less-than($screen-xl) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      @include less-than($screen-md) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      .views-field-field-image,
      .field-content {
        height: 100%;
        width: 100%;
      }

      .brand-list-item{
        border-right: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
        &:nth-child(4n) {
          border-right: none;
        }
      }

      .views-field-field-image{
        .field-content {
          text-align: center;
        }
        a {
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
          max-width: 200px;
        }
      }
    }
  }
}

/*
======================================================
 Brands View
======================================================
*/

.view-brands-landing-page{

  .view-filters{
    border-top: 1px dashed #ededed;
    border-bottom: 1px dashed #ededed;
    padding: 1rem 0 2rem 0;
    margin-bottom: 3rem;

    .views-exposed-widgets{
      display: flex;
      flex-wrap: wrap;
    }

    .views-exposed-widget{
      label{
        display: flex;
        margin-bottom: 1rem;
      }
    }
    .views-submit-button{
      display: none; // hide since it is autosubmit
    }

    @include less-than($screen-sm){
      .views-exposed-widgets{
        flex-direction: column;
        .views-exposed-widget{
          width: 100%;
        }
      }
    }
    @include less-than($screen-md){
      .views-exposed-widget{
        flex: 1;
      }
    }
  }

  .view-content {
    display: grid;
    grid-gap: 6.3rem 0rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin-bottom: 4rem;

    @include less-than($screen-xxl) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include less-than($screen-xl) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include less-than($screen-md) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

}