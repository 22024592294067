/*------------------------------------*\
    Typography
\*------------------------------------*/

body {
  font-family: $base-font;
  font-size: 1.6rem;
  color: $color-base;
}

* { outline: none !important; }

h1,
h2,
h3,
h4,
h5 {
  font-family: inherit;
}

h1 {
  font-size: 6rem;
  line-height: 7.1rem;
  font-weight: 900;
  margin: 1rem 0;
}

h2 {
  font-size: 4rem;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 1rem;
}

h2.title {
  font-size: 3rem;
  line-height: 1.5;
  font-weight: 700;
  padding-left: 2rem;

  @include less-than($screen-lg) {
    font-size: 2rem;
  }
}

h3 {
  font-size: 2rem;
  font-weight: 600;
}

ul {
  margin: 0;
  padding: 0;
}

a {
  color: $color-base;
}
@include less-than($screen-xxl) {
  h1 {
    font-size: 3.5rem;
  }

  h3 {
    font-size: 1.6rem;
  }
}

@include less-than($screen-sm) {
  body {
    font-size: 1.5rem;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  h2 {
    font-size: 2.2rem;
  }

  h3 {
    font-size: 1.5rem;
  }
}

a {
  text-decoration: none;
}

img{
  max-width: 100%;
}
