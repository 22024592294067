.page-cart-checkout-review {
  #review-instructions {
    width: 101.5rem;
    max-width: 100%;
    margin: 0 auto;
  }

  .order-review-table {
    width: 101.5rem;
    max-width: 100%;
    background: #fafafa;
    margin: 2rem auto;
    border: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 151.69%;
    color: #2e2e2e;

    > tbody > tr {
      display: flex;

      &.pane-title-row {
        background: #fdd100;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 151.69%;
        color: #0c0c0c;
        text-align: left;

        td {
          padding: 1rem 1.5rem;
        }
      }

      &.review-button-row {
        background: none;
        border: none;

        form {
          .form-actions {
            display: flex;
            justify-content: center;
            margin-top: 4rem;
          }
        }

        .btn.btn-primary.form-submit {
          color: #fff;
          flex-basis: 35rem;
          margin-left: 2rem;
          @include button($color-success);
        }
      }

      > td {
        padding: 1rem 0;
        flex: 1;

        &.title-col {
          text-align: left;
          padding-left: 0;
          flex-basis: 30%;
          font-weight: 400;
          padding-right: 0.5rem;
        }

        &.data-col {
          flex-basis: 70%;
        }
      }
    }

    .table-responsive {
      .cart-review {
        tr {
          display: flex;
        }

        th {
          border-bottom: 1px dashed #cacaca;
        }

        td,
        th {
          text-align: left;
          padding: 1rem 0;

          &.qty {
            order: 2;
            width: 10%;
          }

          &.products {
            width: 20%;
          }

          &:nth-child(3) {
            flex: 1;
          }

          &.price {
            order: 3;
            width: 15%;
          }
        }
      }
    }
  }

  table {
    tr {
      td {
        padding: 1rem;
      }
    }

    &.cart-review {
      td,
      th {
        width: calc(100% / 3);
        text-align: center;
      }
    }
  }
}
