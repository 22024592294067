.view-downloads {
  .view-filters {
    margin-bottom: 2rem;
    #edit-field-category-tid-wrapper {
      display: flex;
      align-items: center;
      width: 35rem;
      max-width: 100%;

      label {
        margin-right: 2rem;
      }
      .views-widget {
        flex: 1;
      }
    }
  }
  .view-content {
    display: grid;
    grid-gap: 4rem 4rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    @include less-than($screen-lg) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .views-row {
      .views-field {
          .field-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              width: 100%;
              height: auto;
              object-fit: contain;
              max-width: 24rem;
              margin-bottom: 1rem;
            }

            .file-size,
            .file-icon {
              display: none;
            }

            > .file a {
              @extend .btn-primary;
              margin-top: 1rem;
            }

            .title .file a {
              font-size: 1.4rem;
            }
          }
      }
    }
  }
}