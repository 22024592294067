.node-type-product.page-node {
  .page-header {
    display: none;
  }

  .node-product.view-mode-full {
    .group-top {
      display: flex;
      align-items: flex-start;
      margin-top: 2rem;
      margin-bottom: 4rem;

      @include less-than($screen-lg) {
        flex-wrap: wrap;
      }

      @include less-than($screen-sm) {
        flex-direction: column;
      }

      .group-img {
        width: 55.2rem;
        max-width: 100%;
        flex-shrink: 0;
        margin-right: 6rem;

        @include less-than($screen-xxl) {
          width: 35rem;
          margin-right: 3rem;
        }

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      .group-descr {
        flex: 1 0 28rem;
        margin-top: 5rem;

        @include less-than($screen-lg) {
          width: 100%;
          flex-basis: 100%;
          margin-top: 2rem;
        }

        .field-name-title {
          margin-bottom: 1rem;

          h2 {
            font-weight: bold;
            font-size: 50px;
            line-height: 100%;
            color: #222222;
            margin-top: 0;
          }
        }

        .display-price {
          clear: none;
          float: none;
          padding-bottom: 0;
          padding-left: 0;
          text-align: left;

          .uc-price {
            text-transform: uppercase;
            color: #3c3c3c;
            font-weight: bold;
            font-size: 40px;
            line-height: 138.69%;
            color: #222222;
          }

          .price-suffixes {
            font-size: 16px;
            color: #888;
            font-weight: normal;
            margin-left: 1rem;
          }
        }

        .sell-price {
          font-weight: 700;
          font-size: 18px;
          line-height: 138.69%;
          color: #e81818;
        }

        .field-name-gf-product-availability {
          .field-item {
            > div {
              display: flex;
              align-items: center;
              margin-top: 16px;
              text-transform: uppercase;

              &:before {
                font-family: $font-awesome;
                font-size: 20px;
                font-weight: bold;
                margin-right: 1rem;
              }

              &.out-of-stock {
                color: #bb2124;

                &:before {
                  content: '\f00d';
                }
              }

              &.in-stock {
                color: #22bb33;

                &:before {
                  content: '\f00c';
                }
              }
            }
          }
        }

        .field-name-body {
          font-size: 16px;
          line-height: 183.69%;
          color: #3d3d3d;
        }
      }

      .group-cart {
        width: 48.2rem;
        margin-top: 2rem;
        background: #fafafa;
        padding: 3rem;

        .field-name-product-enquiry-button {
          display: none;
          margin-top: 2rem;
          .btn {
            background-color: #77b726;
            text-transform: uppercase;
            width: 100%;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
          }
        }

        @include less-than($screen-lg) {
          width: 100%;
          padding: 1rem 0;
          background: none;
        }

        @include less-than($screen-sm) {
          .add-to-cart {
            form > div {
              flex-direction: column;
              justify-content: center;

              .form-actions {
                margin-top: 2rem;
                width: 100%;
              }
            }
          }
        }

        .add-to-cart {
          form > div {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            > .product-extra {
              width: 100%;
              border-bottom: 1px solid #dfdfdf;
              margin-bottom: 3rem;
              padding-bottom: 2rem;

              > div {
                font-size: 1.6rem;
                line-height: 140.69%;
                color: #222222;
                margin-bottom: 2.5rem;
                position: relative;
                padding-left: 50px;

                .product-landing-fit-info,
                .product-landing-collect-info,
                .product-landing-delivery-info {
                  &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 32px;
                    width: 32px;
                    background: #ffffff;
                    border: 1px solid #a79e9e;
                    border-radius: 50%;
                  }

                  &.selected {
                    &::before {
                      background: $color-success;
                    }

                    &::after {
                      content: '';
                      position: absolute;
                      top: 5px;
                      left: 11px;
                      width: 7px;
                      height: 15px;
                      border: solid white;
                      border-width: 0 4px 4px 0;
                      transform: rotate(45deg);
                    }
                  }

                  b {
                    margin-right: 5px;
                  }

                  > div {
                    margin-bottom: 1rem;
                  }
                }

                .product-landing-fit-info,
                .product-landing-collect-info {
                  > span {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 138.69%;
                    color: #222222;
                    padding-top: 6px;
                    padding-bottom: 6px;
                    @include button($color-primary);

                    &:hover {
                      color: #222222;
                    }
                  }
                }

                // .product-landing-fit-info > div::after {
                //   content: ' The store you have chosen does not have this option available.  Please try again.';

                // }

                // .product-landing-collect-info > div::after {
                //   content: ' Available from 14 stores. Select your store below';

                // }

                // .product-landing-delivery-info > div::after {
                //   content: ' Order now to get this sent to you.';
                // }
              }
            }

            .form-item-qty {
              display: flex;
              align-items: center;
              margin-bottom: 0;

              label {
                font-size: 0;

                &::before {
                  content: 'Qty:';
                  font-weight: bold;
                  font-size: 1.8rem;
                  line-height: 138.69%;
                  color: #222222;
                }
              }

              input.form-control {
                padding: 5px;
                text-align: center;
                margin: 0 10px;
                min-width: 3rem;
                height: 3.4rem;
              }
            }

            .form-actions {
              margin-bottom: 0;
              margin-left: 2rem;
              flex: 1;

              @include less-than($screen-xl) {
                margin-left: 0.5rem;
              }

              .btn {
                font-weight: bold;
                font-size: 1.6rem;
                line-height: 138.69%;
                background: #77b726;
                color: #fff;
                border: 1px solid #dbdbdb;
                border-radius: 6px;
                width: 100%;
                height: 5.6rem;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                padding-left: 5px;
                padding-right: 5px;
                white-space: nowrap;

                span.icon {
                  width: 2.2rem;
                  height: 2rem;
                  background-image: url(../../images/icon-shopping-cart-light.png);
                  background-repeat: no-repeat;
                  flex-shrink: 0;
                }
              }
            }
          }
        }
      }
    }

    .group-spec {
      padding: 0;

      .panel-heading {
        font-weight: bold;
        font-size: 2rem;
        line-height: 138.69%;
        color: #222222;
        background: #f3f3f3;
        width: 100%;
        padding: 5px 1rem;
        margin-bottom: 1rem;
      }

      .panel-body {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: 6rem;

        @include less-than($screen-lg) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-column-gap: 3rem;
        }

        @include less-than($screen-sm) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
          grid-column-gap: 3rem;
        }

        > div {
          border-bottom: 1px dotted $color-gray-lighter;
          display: flex;
          padding: 0.5rem 1rem;
          font-size: 16px;
          line-height: 1.5;
          color: #222222;
          //margin-bottom: 2rem;

          .field-label {
            flex-basis: 23.4rem;
            font-weight: 700;
          }

          .field-items {
          }
        }
      }
    }

    .group-spec-key {
      margin-top: 2rem;

      .field-name-alex-test {
        a {
          @extend .btn-secondary;
          color: $color-primary;
          font-weight: 700;
          font-size: 1.5rem;
          line-height: 151.69%;

          &:hover {
            color: $color-primary;
          }
        }
      }
    }

    .field-name-field-product-related-product {
      width: 100%;
      margin-top: 8rem;
      margin-bottom: 8rem;

      .slick {
        padding: 0 7rem;

        .slick__arrow {
          .slick-prev {
            left: 0;
          }

          .slick-next {
            right: 0;
          }
        }
      }
    }

    .store-selection-block {
      position: fixed;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);

      .store-selection-content {
        background-color: #fff;
        margin: 20rem auto 0;
        padding: 2rem;
        border: 1px solid #888;
        width: 80%;
        max-height: calc(100vh - 20rem);
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;

        @include less-than($screen-lg) {
          margin-top: 28rem;
          max-height: calc(100vh - 28rem);
        }

        .close-store-selection {
          font-size: 0;
          position: absolute;
          right: 1rem;
          top: 2rem;

          &::before {
            @include btn-close;
            content: '';
          }
        }

        .popup-store-product-info {
          font-weight: 700;
          font-size: 30px;
          line-height: 138.69%;
          color: #222222;
          margin-right: 5rem;

          .store-selection-product-img {
            width: 10rem;
            height: 9rem;
            object-fit: contain;
            vertical-align: middle;
            margin-right: 1rem;
          }
        }

        .bt-address-search-wrapper {
          position: relative;
          max-width: 80%;
          margin-right: 1rem;
          width: 62rem;

          &::before {
            content: '';
            width: 11px;
            height: 16px;
            display: inline-block;
            background-image: url(../../images/icon-location-black.png);
            background-repeat: no-repeat;
            position: absolute;
            top: 13px;
            left: 10px;
          }

          &::after {
            content: '';
            width: 14px;
            height: 14px;
            display: inline-block;
            background-image: url(../../images/icon-search-black.png);
            background-repeat: no-repeat;
            position: absolute;
            top: 13px;
            right: 10px;
          }

          input#bt-address-search {
            border: 1px solid #d0d0d0;
            box-sizing: border-box;
            border-radius: 3px;
            height: 4rem;
            padding-left: 3rem;
            font-size: 12px;
            line-height: 166.69%;
            color: #262626;
            width: 100%;
          }

          .address-search-reset {
            float: right;
          }
        }
      }

      .store-selection-table {
        width: 100%;
        font-size: 1.6rem;
        line-height: 138.69%;
        color: #222222;
        margin-top: 2rem;

        thead {
          tr {
            background: #f0f0f0;

            th {
              padding: 1rem;
              text-align: left;
              font-weight: 700;
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 1rem;
              border-bottom: 1px solid #d8d8d8;

              a {
                display: block;
                font-size: 14px;
                line-height: 138.69%;
                text-decoration-line: underline;
                color: #2487e2;
              }

              &.store-selection-select {
                font-size: 1.4rem;

                .pick-up-label {
                  font-weight: 700;
                }

                .store-method-not-available {
                  color: #ce4f33;
                  font-weight: 700;
                }
              }
            }

            &.my-selected-store {
              background-color: #fdf3c7;

              td:first-child {
                position: relative;
                padding-bottom: 20px;

                &:after {
                  content: 'Selected Store';
                  background-color: #fd7900;
                  font-size: 10px;
                  line-height: 138.69%;
                  font-weight: 700;
                  color: #ffffff;
                  border-radius: 10px;
                  padding: 2px 10px;
                  position: absolute;
                  left: 8px;
                  bottom: 5px;
                }
              }
            }
          }
        }
      }

      .btn-wrapper {
        text-align: right;
        margin-top: 3rem;
        width: 100%;
      }
    }
  }
}

.node-product {
  .panel-default {
    border: none;
  }

  header {
    position: relative;
  }

  .field-name-field-substitue-codes {
    width: 100%;
    float: left;
    padding: 2em;

    .node-product {
      position: relative;
      padding: 0.5em 0;
      border-bottom: 1px solid $color-gray-lighter;

      .field-name-uc-product-image {
        float: left;
        width: 3em;
        position: absolute;
        left: 0;
        top: 0.5em;

        img {
          width: 100%;
          height: auto;
          max-height: 3em;
          object-fit: cover;
        }
      }

      .display-price {
        float: right;
        clear: none;
        padding: 1em 0;
        margin: 0;
        width: 5em;
        text-align: right;
        line-height: 1;
      }

      header {
        float: left;

        h2 {
          margin: 0 0 0 4em;
          padding: 1em 0;
        }
      }
    }
  }
}

.node-product.view-mode-search_result {
  border-right: 1px dashed #d7d7d7;
  padding: 0 2.5rem;
  max-width: 40rem;

  .field-name-node-link {
    margin-top: 1rem;
  }
  .field-name-node-link,
  .btn {
    width: 100%;
  }

  @include less-than($screen-md) {
    margin: 0 auto;
  }

  @include less-than($screen-md) {
    border-right: 0;
  }

  .group-specifications {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;

    > .field {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &::before {
        content: '\f111';
        font-family: $font-awesome;
        font-size: 8px;
        margin-right: 8px;
      }
    }
  }

  .field-name-field-cca,
  .field-name-field-technology-term {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;

    .field-label {
      flex: 0 0 auto;
    }

    .field-items {
      flex: 1;
    }
  }

  .field-name-uc-product-image,
  .field-name-popup-product-image-field {
    .field-item a {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 22rem;

      img {
        max-width: 100%;
        max-height: 100%;
        //object-fit: cover;
        transition: transform 0.3s ease-in-out;
      }

      &:hover img {
        transform: scale(1.05);
      }
    }
  }

  .field-name-popup-product-link {
    a {
      font-style: italic;
      font-weight: 900;
      font-size: 2.2rem;
      line-height: 138.69%;
      color: #343434;
    }
  }

  .field-name-body {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 125.7%;
    color: #343434;

    p {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  .group-cart {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .add-to-cart {
      form > div {
        display: flex;
        align-items: center;

        .form-item-qty {
          display: flex;
          align-items: center;
          margin-bottom: 0;

          label {
            font-size: 0;

            &::before {
              content: 'Qty';
              font-weight: bold;
              font-size: 1.5rem;
              line-height: 151.69%;
              color: #0c0c0c;
            }
          }

          input.form-control {
            padding: 5px;
            text-align: center;
            margin: 0 8px;
            min-width: 3rem;
          }
        }

        .form-actions {
          margin-bottom: 0;

          .btn {
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 151.69%;
            color: #0c0c0c;
            padding: 7px 0;
            width: 10.5rem;

            span.icon {
              display: none;
            }
          }
        }
      }
    }

    .display-price {
      display: flex;
      flex-direction: column;

      .uc-price {
        text-transform: uppercase;
        color: #3c3c3c;
        font-weight: 700;
        font-size: 2rem;
        line-height: 164.69%;
      }

      .price-suffixes {
        font-size: 12px;
        color: #888;
        font-weight: normal;
      }
    }
  }
}

.field-name-stock-info {
  padding: 0.5em 0;

  span {
    display: block;
    position: relative;

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 6px;
      margin-right: 0.5em;
      display: inline-block;
    }

    &.stock-green,
    &.national-green {
      &:before {
        background: #53b80f;
      }
    }

    &.stock-amber,
    &.national-amber {
      &:before {
        background: #f68908;
      }
    }
  }
}

// Custom Modal Content
.node-type-product.page-node {
  #modalBackdrop {
    background: rgba(0, 0, 0, 0.8) !important;
  }
  #modalContent {
    .ctools-modal-dialog {
      display: flex;
      justify-content: center;
      align-items: center;

      &.modal-dialog {
        .modal-content {
          position: relative;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          display: flex;
          flex-direction: column;

          @include larger-than($screen-md) {
            width: 60% !important;
          }

          .modal-header {
            background-color: $color-primary;
            border: none;
            color: #000;
            padding: 1.5rem 3rem;
          }

          .modal-title {
            font-size: 2rem;
          }

          .modal-body {
            width: 100% !important;
            max-height: none !important;
            background-color: #fafafa;
            padding: 0 !important;
          }

          .alert {
            width: calc(100% - 30px);
            margin-left: 15px;
            margin-right: 15px;
            border-radius: 0;

            a.close {
              top: 0;
              right: 0;
            }
          }

          form {
            position: relative;
            width: 100%;
            padding: 3rem;

            > div {
              position: relative;
              width: 100%;
              display: grid;
              grid-row-gap: 1.5rem;
              grid-column-gap: 3rem;

              @include larger-than($screen-md) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
                .webform-component--company,
                .webform-component--message,
                .form-actions {
                  grid-column: 1/3;
                }
              }
            }

            .control-label {
              display: flex;
              margin-bottom: 8px;
            }

            .form-item {
              margin-bottom: 0;
              .form-control,
              .form-textarea,
              .grippie {
                width: 100%;
                border-radius: 0;
              }
            }

            .form-actions {
              .form-submit {
                background-color: #77b726;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
  }
}
