/*------------------------------------*\
  Store Finder
\*------------------------------------*/
.location-finder-app {
  .search-bar {
    .button,
    .search-box {
      height: 3.4rem;
      white-space: nowrap;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 1;
    }

    @include less-than($screen-lg) {
      .or-text {
        margin: 0 1rem;
      }
    }
  }
  .list-of-locations {
    margin-bottom: 2rem;
  }
}

/** Page **/
.store-finder{
  .main-container.tw-container{
    > h1.page-header,
    > .tabs--primary{
      @extend .tw-container;
      width: 100%;
    }
    > h1.page-header{
      margin-top: 2rem;
    }
    @include larger-than($screen-md){
      max-width: 100%;
    }
    @include less-than($screen-md){
      > h1.page-header,
      > .tabs--primary{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  /** Content **/
  &--content{
    @extend .tw-container;
    a{
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
    @include less-than($screen-md){
      padding-left: 0;
      padding-right: 0;
    }
  }
  /** Filter **/
  &--filter{
    @extend .tw-container;
    margin: 1rem auto 2rem auto;
    /** Responsive **/
    @include less-than($screen-sm){
      padding-left: 0;
      padding-right: 0;
      .place-input-container,
      .dropdown-input-container{
        width: 100%;
        padding-right: 0 !important;
        input{
          width: 100% !important;
        }
      }
    }
  }

}