.page-faq {
  .view-faqs {
    padding: 1rem 0;

    .view-content {
      border-bottom: 1px dashed #E6E6E6;

      .field-group-div {
        border-top: 1px dashed #E6E6E6;
        padding: 2.5rem 0;
      }
      .field-name-title h2 {
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;    
        color: #4D4D4D;
        margin: 0;
        cursor: pointer;
        display: flex;
        align-items: center;

        &::before {
          content: '\002B';
          color: $color-primary;
          font-size: 2.5rem;
          margin-right: 1.5rem;
          font-weight: 400;
        }
      }
      .field-name-body {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.423823px;
        color: #343434;
        display: none;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.node-type-faq.page-node {

}