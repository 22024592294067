/*------------------------------------*\
    Theme: admin
\*------------------------------------*/
.path-user {
  #contentblock {
    margin: 200px auto;
    width: 80%;
  }
}

.not-logged-in {
  .tabs--primary {
    display: none;
  }
}

/*
======================================================
: Editing Tabs
======================================================
*/
.tabs--primary {
  @apply tw-flex;
  padding: 0.5rem 1rem;
  border: 1px dashed #eee;
  margin: 1rem 0;
  list-style: none;

  a {
    color: #333;
    margin-right: 2.5rem;
    font-size: 1.4rem;
    line-height: 1.5;

    &:hover {
      text-decoration: underline;
    }
  }
}

/*
======================================================
: masquerade
======================================================
*/
section#block-masquerade-masquerade {
  position: fixed;
  width: 100vw;
  bottom: 0;
  height: 4rem;
  background: #333;
  color: #fff;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  z-index: 10;

  h2 {
    margin-bottom: 0;
    margin-right: 1rem;
    color: inherit;
  }

  form {
    > div,
    .container-inilne {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    input {
      border-radius: 3px;
      background-color: #000;
      color: #c1c1c1;
      padding: 0.5rem;
      text-indent: 0.5rem;
    }

    button[type='submit'] {
      display: inline-block;
      margin: 0 2rem;
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
      width: auto;
      @include button($color-primary);
    }
  }
}
